// Newsletter inscription
.newsletter {
    color: $color-white;
    &__title {
        font: $normal 1.9em $typo-2;
        text-transform: uppercase;
        margin: 0 0 0.5em;
    }
    form {
        margin: 0;
    }
    &__fields {
        position: relative;
    }
    &__fields-wrapper {
    }
    &__field-wrapper {
        color: $color-white;
        label {
            color: inherit;
            font-weight: $normal;
        }
        input {
            background: none;
            border-width: 0 0 1px;
            border-color: currentColor;
            color: inherit;
            transition: $transition;
            padding: em(.95, $input__font-size) em(3, $input__font-size) em(.95, $input__font-size) 0;
        }
    }
    &__button-wrapper {
        position: absolute;
        bottom: 1px;
        right: 1px;
        .button-1 {
            background: none;
            border: none;
            padding: 0.85em;
            svg {
                width: 16px;
                height: 16px;
                transition: $transition;
            }
            &:hover,
            &:focus {
                svg {
                    fill: $color-4--2;
                }
            }
        }
    }
    &__links {
        margin-top: 2rem;
        li {
            color: $color-white;
            a {
                color: inherit;
                &:before {
                    color: inherit;
                }
                &:hover,
                &:focus {
                    color: inherit;
                    text-decoration: underline;
                }
            }
            &.newsletter__icon-archives a:before {
                content: '\e03d';
            }
            &.newsletter__icon-unsubscribe a:before {
                content: '\e058';
            }
        }
    }
}
.newsletter input { color: #fff; }
.newsletter input::-webkit-input-placeholder { color: #fff; }
.newsletter input::-moz-placeholder { color: #fff; }
.newsletter input:-ms-input-placeholder { color: #fff; }
.newsletter input:-moz-placeholder { color: #fff; }
