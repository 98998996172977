// @name block rencontre

// @name Block rencontre in go-so-far
.block-rencontre {
    padding: 7rem 0 7rem;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.5em 3.5em;
        .rencontre-item {
            width: calc(50% - 3em);
            margin: 1.5em;
        }
    }
}

.rencontre-item {
    position: relative;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    &__img {
        display: block;
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
    }
    &__wrap {
        display: flex;
        overflow: hidden;
        flex-grow: 1;
    }
    &__name {
        font-size: 1.8em;
        font-weight: $normal;
        color: $color-white;
        font-family: $typo-2;
        text-align: right;
        text-transform: uppercase;
        position: relative;
        z-index: 0;
        padding: 4rem 7.5rem;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 200%;
            height: 100%;
            background-color: $color-1--2;
            transform: skewX(-24deg);
            transform-origin: top right;
            z-index: -1;
        }
    }
    &__content {
        padding: 4rem 5rem 4rem 2rem;
    }
    &__title {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2.4em;
        margin-top: 0;
        color: $color-3--2;
        text-transform: none;
    }
    &__link {
        color: inherit;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    &__desc {
        color: $color-3--2;
        font-size: 1.4em;
    }
    &__footer {}
}
