.logo {
    float: left;
    font-size: 1.3em;
    position: relative;
    margin-left: -3em;
    z-index: 15;
    height: 100%;
    span,
    a {
        display: block;
        position: relative;
        padding: 0 3em 0 2em;
        height: 100%;
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-white linear-gradient(to right, transparent 0%, transparent 85%, rgba($color-3--1, 0.3) 100%);
        transform: skewX(-23deg);
        z-index: 0;
    }
    .link-logo {
        position: relative;
        z-index: 10;
    }
    img {
        width: auto;
        height: 100%;
    }
}
