// @name block actualites

// @name Block actualites in go-so-far
.block-actualites {
    background-color: $color-3--4;
    padding-bottom: 7rem;
    padding-top: 7rem;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.5em 1.5em;
        .actualites-item {
            width: calc(25% - 3em);
            margin: 1.5em;
        }
    }
    .heading-block {
        margin-bottom: 5rem;

        .title-1 {
            background-color: $color-3--4;
        }
    }
}

.actualites-item {
    position: relative;
    background-color: $color-1--3;
    color: $color-white;
    transition: all 0.5s ease;
    opacity: 1;
    &:nth-child(2n) {
        background-color: $color-1--2;
        .actualites-item__wrap:before {
            background-color: $color-4--1;
        }
        .actualites-item__link {
            outline-color: $color-1--3;
        }
    }
    &:hover {
        opacity: 0.9;
    }
    &__img {
        display: block;
        img {
            display: block;
            width: 100%;
        }
    }
    &__img + &__wrap {
        padding-top: 0;
        &:before {
            content: '';
            display: block;
            background-color: $color-2--1;
            margin: 0 auto 0.5em;
            width: 5px;
            height: 2.4rem;
            transform: translate(0, -50%) skewX(-25deg);
        }
    }
    &__wrap {
        text-align: center;
        color: $color-white;
        padding: 10em 2em 3em ;
    }
    &__category {
        font-size: 1.6em;
        font-weight: $normal;
        color: $color-white;
    }
    &__title {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.8em;
        text-transform: none;
    }
    &__link {
        color: $color-white;
        display: block;
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
        }
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    &__footer {}
}
