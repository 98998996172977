// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 1em;
    @extend .clear-fix;
    .home & {
        max-width: 124rem;
    }
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    &:before, &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
    }
    &:before {
        left: -9990px;
    }
    &:after {
        right: -9990px;
    }
    // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--2;
        &:before, &:after {
            background: $color-3--2;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 34;
$column-bloc__padding-left: 7;
.column-bloc {
    width: #{$column-bloc__width}em;
    padding-left: #{$column-bloc__padding-left}em;
    margin-bottom: 2em;
}

// Last widget in sidebar
.section-main__aside {
    .setPos:last-child  .column-bloc {
        margin-bottom: 0;
    }
}

.column-bloc__wrapper {
    padding: 0;
}
