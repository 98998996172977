%titles-general {
    font-family: $typo-2;
    text-transform: uppercase;
    font-weight: $normal;
    line-height: 1em;
}

.heading-block {
    position: relative;
    color: $color-1--2;
    margin: 4em 0 2em;
    h2,
    h3 {
        display: inline-block;
        padding-right: 0.5em;
        margin: 0 !important;
        background-color: $color-white;
        position: relative;
        z-index: 10;
    }
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 20px;
        position: absolute;
        margin-top: -10px;
        left: 0;
        top: 50%;
        z-index: 1;
        @extend %bg-pattern-dark;
    }
    &--center {
        text-align: center;
        h2,
        h3 {
            padding: 0 0.25em;
        }
    }
    &--icon {
        i {
            margin-right: 0.5em;
            font-size: 4.5rem;
            color: $color-1--3;
            vertical-align: -0.15em;
        }
        h2,
        h3 {
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 5px;
                height: 0.55em;
                left: 1.35em;
                top: 60%;
            }
            &:before {
                background-color: $color-1--2;
                transform: translate(175%, -70%) skewX(-25deg);
            }
            &:after {
                background-color: $color-4--2;
                transform: translate(0, -35%) skewX(-25deg);
            }
        }
    }
}

// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4;
.title-1 {
    font-size: #{$title--1__font-size}em;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
    @extend %titles-general;
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 3.4;
.title-2 {
    font-size: #{$title--2__font-size}em;
    margin: em(3.5, $title--2__font-size) 0 em(2.5, $title--2__font-size);
    @extend %titles-general;
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    font-size: #{$title--3__font-size}em;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    @extend %titles-general;
}
