// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 96rem;
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
.column-bloc {
    width: 100%;
    padding-left: 0;
}
