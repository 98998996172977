.home-gallery {
    .image-gallery {
        padding: 0 2em;
        &__lg {
            width: 50%;
            margin: 0 auto;
            order: 1;
        }
        &__sm {
            width: 100%;
            .image-gallery-item {
                width: 50%;
            }
        }
    }
    &__footer {
        text-align: center;
    }
}
.image-gallery-item {
    color: $color-white;

    &:after,
    &:before {
        display: none !important;
    }

    &__img {
        padding: 0;
        height: auto;
        img {
            position: static;
            transform: translate(0, 0);
            width: 100%;
        }
        &:after {
            display: none !important;
        }
    }
    &__content {
        position: static;
        visibility: visible;
        opacity: 1;
        width: auto !important;
        margin: 2em;
        transform: translate(0, 0) !important;
        .category {
            font-size: 1.4em;
        }
    }
}
