// @name block downloads

// @name Block download in go-so-far
.block-download {
    &__wrapper {
        &:after {
            display: none;
        }
    }
    &__item {
        width: 100%;
        padding: 1.5rem 1rem 1.5rem 4.5rem;
        i {
            left: 0;
        }
    }
}
