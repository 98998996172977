// @name Tooltip Full CSS
// @description Not for accessibility
// @markup <a href="#" class="tooltip-css" data-tooltip="Lorem ipsum">Lorem</a>
// @markup <span class="tooltip-css" tabindex="1" data-tooltip="Lorem ipsum">Lorem</span>
// bottom left is default

$tooltip-bg-color: $color-black;
.tooltip-css {
    position: relative;
    //cursor: help;

    &:before,
    &:after {
        position: absolute;
        display: block;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        z-index: -98;
        transform: translateY(-100%);
    }

    &:before {
        top: calc(100% + 10px);
        content: attr(data-tooltip);
        speak: none;
        white-space: nowrap;
        color: $color-white;
        font-size: 1.2em;
        font-weight: $bold;
        text-transform: uppercase;
        background: $tooltip-bg-color;
        padding: 10px 15px;
        transition: all ease-out .4s;
    }

    &:after {
        content: '';
        top: 100%;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 0;
        border-bottom: 0;
        border-left: 10px solid $tooltip-bg-color;
        transition: all ease-out .1s;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        &:before,
        &:after {
            z-index: 98;
            opacity: 1;
            visibility: visible;
            transform: translateY(0) !important;
            transition: all ease-out .1s;
        }
    }

    &.tooltip-css--top {
        &:before,
        &:after {
            transform: translateY(100%);
        }
        &:before {
            top: auto;
            bottom: calc(100% + 10px);
        }
        &:after {
            top: auto;
            bottom: 100%;
            border-top: 0;
            border-right: 0;
            border-bottom: 10px solid transparent;
            border-left: 10px solid $tooltip-bg-color;
        }
    }

    &.tooltip-css--right {
        &:before,
        &:after {
            left: inherit;
        }
        &:before {
            right: -15px;
        }
        &:after {
            right: 0;
        }
    }

}
