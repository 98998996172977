.home-convenient {

    $this: &;

    .section-main__wrapper {
        padding-bottom: 5em;
    }

    .title-3 {
        color: $color-3--2;
        font-weight: $light;
        margin: 0.5em 0 1em;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 69%; // pixel perfect fit o_O
        margin-bottom: 2rem;
        + #{$this}__item {
            width: 31%;
        }
        .home-convenient-section {
            float: left;
            width: 50%;
            padding-right: 4rem;
            &__img {
                display: block;
                position: relative;
                img {
                    width: 100%;
                    height: auto;
                }
                &:after {
                    content: '';
                    display: block;
                    background-color: $color-4--1;
                    margin: 0 auto;
                    width: 5px;
                    height: 2.4rem;
                    transform: translate(0, -50%) skewX(-25deg);
                }
            }
            &__content {
                text-align: center;
                p {
                    color: $color-1--2;
                    font-family: $typo-2;
                    font-size: 1.3em;
                    margin-bottom: 1em;
                    font-weight: $bold;
                    text-transform: uppercase;
                }
                h4 {
                    font-size: 1.9em;
                    font-weight: $normal;
                    a {
                        color: $color-black;
                    }
                }
            }
            &__list {
                display: block;
                margin-bottom: 2em;
                li {
                    $border-color: $color-3--4;
                    border-bottom: 1px solid $border-color;
                    &:first-child {
                        border-top: 1px solid $border-color;
                    }
                }
                a {
                    display: block;
                    position: relative;
                    margin: 0.25rem 0.5rem;
                    padding: 0.5rem 3rem;
                    color: $color-3--3;
                    font-size: 1.6em;
                    font-family: $typo-2;
                    font-weight: $light;
                    @include default-icons-absolute-before('\E01B', 2.6rem, $color-2--1, 50%, 0, inherit, inherit, 'icons-project');
                    @include default-icons-absolute-after('\E011', 2.5rem, $color-white, 50%, 0, inherit, inherit);
                    &:before {
                        transform: translateY(-50%) rotate(15deg);
                        transition: all 0.3s ease;
                    }
                    &:after {
                        transform: translateY(-50%);
                    }
                    &:hover,
                    &:focus {
                        &:before {
                            color: rgba($color-2--1, 0.75);
                        }
                    }
                    img {
                        position: absolute;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        width: auto;
                        height: auto;
                        max-width: 20px;
                        max-height: 20px;
                    }
                }
            }
            &__readmore {
                a {
                    color: $color-2--2;
                    text-transform: uppercase;
                    font-size: 1.4em;
                    font-weight: $normal;
                    font-family: $typo-2;
                    @include default-icons-before('\E01B', 0 5px 0 0, 0.75em, currentColor, -0.1em);
                }
            }
        }
        .home-convenient-rapides {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -0.5em;
            li {
                text-align: center;
                width: (100% / 3);
                margin-bottom: 1rem;
                a {
                    display: block;
                    padding: 0 0.5rem;
                    text-transform: uppercase;
                    font-family: $typo-2;
                    font-size: 1.4em;
                    color: $color-3--2;
                    &:hover {
                        span:before {
                            color: $color-1--3;
                        }
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    padding-bottom: 8rem;
                    margin-bottom: 1em;
                    @include default-icons-absolute-before('\E01B', 8rem, $color-1--2, 50%, inherit, inherit, 50%, 'icons-project');
                    &:before {
                        transform: translate(-50%, -50%) rotate(15deg);
                        transition: color 0.25s ease;
                    }

                }
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    position: absolute;
                    z-index: 2;
                    max-width: 45px;
                    max-height: 45px;
                }

                &:nth-child(3) {
                    a {
                        span {
                            &::before {
                                color: $color-4--4;
                            }
                        }

                        &:hover,
                        &:focus {
                            span {
                                &::before {
                                    color: darken($color-4--4, 13);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6) {
                    a {
                        span {
                            &::before {
                                color: $color-4--5;
                            }
                        }

                        &:hover,
                        &:focus {
                            span {
                                &::before {
                                    color: darken($color-4--5, 13);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-signaler {
        position: relative;
        background-color: $color-2--2;
        margin: 4rem 0;
        @extend %bg-pattern-dark;
        color: $color-white;
        z-index: 1;
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 5px;
            height: 2rem;
            left: 10%;
            top: 0.5rem;
        }
        &:before {
            background-color: $color-1--2;
            transform: translate(175%, -70%) skewX(-25deg);
        }
        &:after {
            background-color: $color-4--2;
            transform: translate(0, -35%) skewX(-25deg);
        }
        &__wrap {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 2rem 4.5rem 2rem 6rem;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
            }
            &:before {
                left: 0;
                right: 0;
                height: 50%;
                background-image: linear-gradient($color-2--2, transparent);
                z-index: -1;
            }
            &:after {
                left: 14rem;
                height: 100%;
                width: 100%;
                background: inherit;
                background: $color-2--1;
                transform: skewX(-24deg);
                z-index: -1;
            }
        }
        span {
            flex-shrink: 0;
            margin-right: 5rem;
            img {
                width: 100%;
                height: auto;
                max-width: 50px;
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                background-color: $color-2--2;
            }
        }
        h2 {
            font-weight: $normal;
            font-family: $typo-2;
            text-transform: uppercase;
            font-size: 2.6em;
            margin: 0 2rem;
            white-space: nowrap;
        }
        p {
            margin: 0 10rem 0 4rem;
            font-size: 1.4em;
            line-height: 1.2;
        }
        a {
            display: block;
            i {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                &:before {
                    position: absolute;
                    display: block;
                    font-size: 4rem;
                    line-height: 4rem;
                    top: 0;
                    left: 0;
                    color: $color-4--2;
                    transform: rotate(15deg);
                    transition: all 0.3s ease;
                }
                @include default-icons-absolute-after ('\E01B', 1.25em, $color-white, 50%, inherit, inherit, 50%);
                &:after {
                    transform: translate(-50%, -50%);
                }
            }
            &:hover,
            &:focus {
                i:before {
                    color: $color-4--3;
                }
            }
        }

    }
}
