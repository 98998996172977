/* stylelint-disable */
#tarteaucitronRoot {
    .tarteaucitronH1 {
        font-size: 2.5rem;
    }

    div#tarteaucitronInfo {
        font-size: 1.6rem !important;

        a {
            font-size: 1.6rem;
        }
    }

    #tarteaucitronPersonalize {
        background: $color-white;
        border: solid 1px rgba($color-3--2, 0.5);
        color: $color-black;

        @include on-event() {
            background: transparent;
            color: $color-white;
        }
    }

    #tarteaucitronAlertBig {
        box-sizing: border-box !important;
        padding: 2rem 3rem !important;

        #tarteaucitronCloseAlert {
            background: transparent;
            color: $color-white;
        }

        > button {
            @media (max-width: #{$smartphone}px) {
                margin: 0.5rem;
            }
        }

        #tarteaucitronPersonalize, #tarteaucitronPersonalize2,
        #tarteaucitronAllDenied, #tarteaucitronAllDenied2 {
            background: $color-1--2;
            border: solid 1px $color-1--2;
            color: $color-white;

            @include on-event() {
                background: $color-2--2;
                border-color: $color-2--2;
            }
        }
    }

    #tarteaucitron {
        #tarteaucitronServices {

            .tarteaucitronDetails {
                background: $color-black;
                display: block !important;
                max-width: inherit;
                padding: 10px 20px;
                position: static;
            }

            .tarteaucitronTitle {
                background: $color-black;

                button {
                    margin-top: 0;
                }
            }

            .tarteaucitronLine {
                &.tarteaucitronIsAllowed {
                    border-color: $color-1--2 !important;
                }

                &.tarteaucitronIsDenied {
                    border-color: $color-1--2 !important;
                }
            }
        }

        .tarteaucitronDeny {
            background-color: $color-1--2 !important;
        }
    }

    .tarteaucitronAllow[style*='background-color'] {
        background-color: $color-1--2 !important;
    }

    .tarteaucitronIsAllowed .tarteaucitronAllow {
        background-color: $color-1--2 !important;
    }
}

.tarteaucitronLine.tarteaucitronIsAllowed {
    border-left: 0.5rem solid $color-1--2 !important;
}

#tarteaucitronDisclaimerAlert {
    display: block;
    margin: 0 0 1rem !important;
    text-align: center !important;
}

div#tarteaucitronInfo {
    position: inherit !important;
}

#tarteaucitronAlertSmall {
    left: 0;
    right: auto !important;
}

#fancyboxLine {
    display: none;
}

/* stylelint-enable */
