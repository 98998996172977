@charset "UTF-8";

// 00-configuration
@import "00-configuration/_configuration.scss";
@import "00-configuration/_mixins.scss";
@import "00-configuration/_framework.scss";
@import "00-configuration/_base.scss";
@import "00-configuration/_grillade.scss";
@import "00-configuration/_grid.scss";

@import "../../node_modules/swiper/src/swiper";

// Desktop view [0 ; ∞]
// 01-utils
@import "01-default/01-utils/_reset.scss";
@import "01-default/01-utils/_styleguide.scss";
// 02-lib
@import "01-default/02-lib/_drop-down-menu.scss";
@import "01-default/02-lib/_access-tooltip.scss";
@import "01-default/02-lib/_tooltip-css.scss";
@import "01-default/02-lib/_fancybox.scss";
@import "01-default/02-lib/_datepicker.scss";
@import "01-default/02-lib/_jquery.timepicker.scss";
@import "01-default/02-lib/_jquery.jscrollpane.scss";
@import "01-default/02-lib/_tarteraucitron.scss";
// 03-partials
@import "01-default/03-partials/_forms.scss";
@import "01-default/03-partials/_buttons.scss";
@import "01-default/03-partials/_links.scss";
@import "01-default/03-partials/_wrappers.scss";
@import "01-default/03-partials/_layouts.scss";
@import "01-default/03-partials/_text.scss";
@import "01-default/03-partials/_heading.scss";
@import "01-default/03-partials/_titles.scss";
@import "01-default/03-partials/_pagers.scss";
@import "01-default/03-partials/_progressbar.scss";
@import "01-default/03-partials/_filters.scss";
@import "01-default/03-partials/_rte.scss";
@import "01-default/03-partials/_typo3.scss";
@import "01-default/03-partials/_booking.scss";
@import "01-default/03-partials/menus/_menu-skip.scss";
@import "01-default/03-partials/menus/_menu-stratis.scss";
@import "01-default/03-partials/menus/_menu-main.scss";
@import "01-default/03-partials/menus/_menu-cross.scss";
@import "01-default/03-partials/menus/_menu-social.scss";
//@import "01-default/03-partials/menus/_menu-main-1.scss";
@import "01-default/03-partials/menus/_menu-main-11.scss";
@import "01-default/03-partials/menus/_menu-user.scss";
@import "01-default/03-partials/lists/_lists.scss";
@import "01-default/03-partials/lists/_list-content.scss";
@import "01-default/03-partials/lists/_list-type-1.scss";
@import "01-default/03-partials/lists/_list-type-1--event.scss";
@import "01-default/03-partials/lists/_list-type-1--glossary.scss";
@import "01-default/03-partials/lists/_list-type-1--poll.scss";
@import "01-default/03-partials/lists/_list-type-2.scss";
@import "01-default/03-partials/lists/_list-type-2--publications.scss";
@import "01-default/03-partials/lists/_list-type-3.scss";
@import "01-default/03-partials/lists/_list-type-4.scss";
@import "01-default/03-partials/homepage/_home-banner.scss";
@import "01-default/03-partials/homepage/_home-convenient.scss";
@import "01-default/03-partials/homepage/_home-espace.scss";
@import "01-default/03-partials/homepage/_home-gallery.scss";
@import "01-default/03-partials/homepage/_home-rencontre.scss";
@import "01-default/03-partials/singles/_single.scss";
@import "01-default/03-partials/singles/_single--news.scss";
@import "01-default/03-partials/singles/_single--poll.scss";
@import "01-default/03-partials/objects/_breadcrumb.scss";
@import "01-default/03-partials/objects/_tools.scss";
@import "01-default/03-partials/objects/_cookies.scss";
@import "01-default/03-partials/objects/_logo.scss";
@import "01-default/03-partials/objects/_stratis.scss";
@import "01-default/03-partials/objects/_page-number.scss";
@import "01-default/03-partials/objects/_form-navigation.scss";
@import "01-default/03-partials/objects/_galeries.scss";
@import "01-default/03-partials/objects/_parallax.scss";
@import "01-default/03-partials/objects/_videos.scss";
@import "01-default/03-partials/objects/_click-roll.scss";
@import "01-default/03-partials/objects/_block-agenda.scss";
@import "01-default/03-partials/objects/_block-organigrame.scss";
@import "01-default/03-partials/objects/_popin-homepage.scss";
@import "01-default/03-partials/objects/_print-banner.scss";
@import "01-default/03-partials/objects/_newsletter.scss";
@import "01-default/03-partials/objects/_search.scss";
@import "01-default/03-partials/objects/_go-to-top.scss";
@import "01-default/03-partials/objects/_subpages.scss";
@import "01-default/03-partials/objects/_sitemap.scss";
@import "01-default/03-partials/objects/_list-document.scss";
@import "01-default/03-partials/objects/_list-infos.scss";
@import "01-default/03-partials/objects/_comments.scss";
@import "01-default/03-partials/objects/_site-infos.scss";
@import "01-default/03-partials/objects/_bloc-news.scss";
@import "01-default/03-partials/objects/_bloc-event.scss";
@import "01-default/03-partials/objects/_bloc-publications.scss";
@import "01-default/03-partials/objects/_bloc-directory.scss";
@import "01-default/03-partials/objects/_bloc-contact.scss";
@import "01-default/03-partials/objects/_block-downloads.scss";
@import "01-default/03-partials/objects/_block-actualites.scss";
// 05-shame
@import "01-default/05-shame/_shame.scss";

// @name Tablet only view [768 ; 1219]
@media (min-width:#{$smartphone+1}px) and (max-width: #{$tablet}px) {
    @import "02-tablet/03-partials/objects/_bloc-contact.scss";
    @import "02-tablet/03-partials/objects/_bloc-publications.scss";
}

// @name Tablet view [768 ; 1219]

@media (max-width: #{$tablet}px) {
    // 01-utils
    @import "02-tablet/01-utils/_reset.scss";
    @import "02-tablet/01-utils/_styleguide.scss";

    // 02-lib
    @import "02-tablet/02-lib/_fancybox.scss";

    // 03-partials
    @import "02-tablet/03-partials/_forms.scss";
    @import "02-tablet/03-partials/_buttons.scss";
    @import "02-tablet/03-partials/_wrappers.scss";
    @import "02-tablet/03-partials/_layouts.scss";
    @import "02-tablet/03-partials/_titles.scss";
    @import "02-tablet/03-partials/_heading.scss";
    @import "02-tablet/03-partials/_pagers.scss";
    @import "02-tablet/03-partials/_rte.scss";
    @import "02-tablet/03-partials/menus/_menu-skip.scss";
    @import "02-tablet/03-partials/menus/_menu-social.scss";
    @import "02-tablet/03-partials/menus/_menu-stratis.scss";
    //@import "02-tablet/03-partials/menus/_menu-main-1.scss";
    @import "02-tablet/03-partials/menus/_menu-main-11.scss";
    @import "02-tablet/03-partials/menus/_menu-user.scss";
    @import "02-tablet/03-partials/homepage/_home-banner.scss";
    @import "02-tablet/03-partials/homepage/_home-convenient.scss";
    @import "02-tablet/03-partials/homepage/_home-espace.scss";
    @import "02-tablet/03-partials/homepage/_home-gallery.scss";
    @import "02-tablet/03-partials/homepage/_home-rencontre.scss";
    @import "02-tablet/03-partials/lists/_list-type-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1--event.scss";
    @import "02-tablet/03-partials/lists/_list-type-2.scss";
    @import "02-tablet/03-partials/lists/_list-type-3.scss";
    @import "02-tablet/03-partials/objects/_search.scss";
    @import "02-tablet/03-partials/objects/_cookies.scss";
    @import "02-tablet/03-partials/objects/_block-agenda.scss";
    @import "02-tablet/03-partials/objects/_list-document.scss";
    @import "02-tablet/03-partials/objects/_list-infos.scss";
    @import "02-tablet/03-partials/objects/_site-infos.scss";
    @import "02-tablet/03-partials/objects/_comments.scss";
    @import "02-tablet/03-partials/objects/_block-downloads.scss";
    @import "02-tablet/03-partials/objects/_block-actualites.scss";

    // 05-shame
    @import "02-tablet/05-shame/_shame.scss";
}

// @name Smartphone view [0 ; 768]

@media (max-width: #{$smartphone}px) {
    // 03-partials
    @import "03-smartphone/03-partials/_forms.scss";
    @import "03-smartphone/03-partials/_buttons.scss";
    @import "03-smartphone/03-partials/_wrappers.scss";
    @import "03-smartphone/03-partials/_layouts.scss";
    @import "03-smartphone/03-partials/_rte.scss";
    @import "03-smartphone/03-partials/_filters.scss";
    @import "03-smartphone/03-partials/_text.scss";
    @import "03-smartphone/03-partials/_heading.scss";
    @import "03-smartphone/03-partials/_titles.scss";
    @import "03-smartphone/03-partials/_typo3.scss";
    //@import "03-smartphone/03-partials/menus/_menu-main-1.scss";
    @import "03-smartphone/03-partials/menus/_menu-user.scss";
    @import "03-smartphone/03-partials/lists/_list-type-1.scss";
    @import "03-smartphone/03-partials/lists/_list-type-1--event.scss";
    @import "03-smartphone/03-partials/lists/_list-type-2.scss";
    @import "03-smartphone/03-partials/lists/_list-type-3.scss";
    @import "03-smartphone/03-partials/homepage/_home-banner.scss";
    @import "03-smartphone/03-partials/homepage/_home-convenient.scss";
    @import "03-smartphone/03-partials/homepage/_home-espace.scss";
    @import "03-smartphone/03-partials/homepage/_home-gallery.scss";
    @import "03-smartphone/03-partials/homepage/_home-rencontre.scss";
    @import "03-smartphone/03-partials/singles/_single--poll.scss";
    @import "03-smartphone/03-partials/objects/_logo.scss";
    @import "03-smartphone/03-partials/objects/_galeries.scss";
    @import "03-smartphone/03-partials/objects/_search.scss";
    @import "03-smartphone/03-partials/objects/_parallax.scss";
    @import "03-smartphone/03-partials/objects/_block-agenda.scss";
    @import "03-smartphone/03-partials/objects/_block-organigrame.scss";
    @import "03-smartphone/03-partials/objects/_sitemap.scss";
    @import "03-smartphone/03-partials/objects/_site-infos.scss";
    @import "03-smartphone/03-partials/objects/_subpages.scss";
    @import "03-smartphone/03-partials/objects/_block-downloads.scss";
    @import "03-smartphone/03-partials/objects/_block-actualites.scss";
    @import "03-smartphone/03-partials/objects/_bloc-publications.scss";

    // 05-shame
    @import "03-smartphone/05-shame/_shame.scss";
}
