// @name Header page
.header {
    @extend .clear-fix;
    background-color: $color-1--2;
    position: relative;
    &__wrapper {
        //@extend .wrapper-main;
        margin-left: 6.5rem;
        //width: 100%;
        background-color: $color-white;
        height: 11.7rem;
        transition: all 200ms ease;
        display: flex;
        align-items: center;
        &:after {
            content: none;
        }
        .menu-main-1__wrapper {
            flex-grow: 1;
        }
    }
}

// @name Top of content
.top-of-content {
    background: $color-3--4;
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding: 2em 0 3em;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 9px;
        @extend %bg-pattern-dark;
    }

}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {

}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 6em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: table;
    table-layout: fixed;
    width: 100%;
    > * {
        display: table-cell;
        vertical-align: top;
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background-color: $color-1--2;
    @extend %bg-pattern-dark;
    position: relative;
    a,
    button,
    input {
        &:focus {
            outline-color: $color-4--2;
        }
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, rgba($color-1--2, 1) 0%, rgba($color-1--2, 1) 80%, rgba($color-1--2, 0) 100%);
    }
    .footer__wrapper {
        @extend .wrapper-main;
        padding-top: 6em;
        padding-bottom: 6em;
        position: relative;
    }
    .footer__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        justify-content: space-between;
        > * {
            margin: 0 1.5em;
            &:not(:last-child) {
                margin-right: 3em;
            }
        }
        .menu-cross {
            width: 150px;
            // _menu-cross.scss
        }
        .newsletter {
            width: 250px;
            // _newsletter.scss
        }
        .site-infos {
            // _site-infos.scss
        }
    }
}
