// @name bloc publications
.column-bloc.bloc-publications {
    text-align: center;
    .bloc-publications__item {
        margin-bottom: 2em;
    }
    .link-view__feature .button-1 {
        margin: 0 auto;
    }
}

.list-infos {}

.bloc-publications {
    .list-infos__button {
        .button-1--no-text {
            border: none;
            &:after {
                display: none !important;
            }
        }
    }
}
.list-infos {
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .list-infos__button {
        .button-1--no-text {
            font-size: 1.3em;
            padding: 1.155em;
            &:after {
                display: none;
            }
        }
    }
    &__download {
        &:before {
            content: '\e063';
        }
    }
    &__book {
        margin-left: 2px;
        background: $color-3--4;
        color: $color-1--3;
        &:before {
            content: '\e06e';
        }
        &:hover,
        &:focus {
            color: $color-white;
            background: $color-1--3;
        }
    }
}

// @name Bloc publications in column and in go-so-far
.bloc-publications__picture {
    display: table;
    border: 1px solid $color-3--4;
    padding: 0 0.5em;
    margin: 0 auto;
    img {
        display: block;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
    text-align: center;
    margin: 1em 0;
    .category {
        font-weight: $normal;
    }
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 1.6;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-1;
    font-weight: $light;
    margin: em(.8, $bloc-publications__title__font-size) 0;
    color: $color-black;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.bloc-publications__subtitle {
    font-size: 1.3em;
}

.list-infos--publications {
    .list-infos__buttons {
        display: block !important;
    }
    .button-1 {
        margin: 0 0 0.5rem 0;
        text-align: center;
        width: 144px;
        padding: em(1.3, $button-1__font-size) em(1, $button-1__font-size);
        &:before {
            position: static;
            top: auto;
            left: auto;
            bottom: auto;
            right: auto;
            margin-right: 5px;
            vertical-align:  -.1rem;
        }
        &:after {
            color: currentColor !important;
        }
        &.list-infos__book {
            background: $color-white;
            &:hover,
            &:focus {
                background: $color-1--3;
            }
        }
        &.list-infos__download {
            &:hover,
            &:focus {
                background: $color-white;
            }
        }
    }
}

.block-kiosque-newlsetter {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding: 3rem 0 0;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4rem;
    }
}

.block-kiosque {
    width: ((100% / 3)*2);
    margin-bottom: 5rem;
    position: relative;
    padding-right: 5rem;
    z-index: 5;
    .bloc-publications__item {
        @extend .clear-fix;
        .bloc-publications__picture {
            float: left;
            margin-right: 2rem;
        }
        .bloc-publications__wrapper {
            text-align: left;
        }
        .list-infos__buttons {
            justify-content: flex-start;
        }
    }
    .bloc-publications {
        margin-bottom: 4rem;
        display: flex;
    }
    &__big {
        margin-right: 4rem;
        .bloc-publications__wrapper {
            .category {
                font-size: 1.6em;
            }
            .bloc-publications__title,
            .bloc-publications__subtitle {
                font-weight: $normal;
                color: $color-3--3;
            }
            .bloc-publications__title {
                font-size: 2.4em;
            }
        }
    }
    &__small {
        .bloc-publications__item {
            + .bloc-publications__item {
                margin-top: 2rem;
            }
        }

    }
}

.block-newlsetter {
    //width: (100% / 3);
    width: calc((100% / 3) + 6rem);
    margin-left: -6rem;
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 300%;
        z-index: -1;
    }
    &:before {
        background-color: $color-2--2;
        @extend %bg-pattern-dark;
    }
    &:after {
        background-image: linear-gradient($color-2--2 50%, transparent 75%);
    }
    .title-2 {
        color: $color-4--2;
        font-size: 2.3em;
        margin: 1.5em 0;
        font-weight: $light;
    }
    &__wrap {
        padding: 1rem 0 1rem 20rem;
        height: 100%;
        min-width: 200px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            right: calc(100% - 24rem);
            top: -25%;
            height: 200%;
            transform: rotate(24deg);
            transform-origin: right top;
            background-color: $color-white;
            z-index: 10;
        }
    }
    .newsletter__fields {
    }
    .block-newlsetter__wrap {
        overflow: initial;
    }
    .newsletter__fields-wrapper {
        input {
            color: $color-white;
            font-size: 1.3em;
            font-weight: $light;
        }
    }
    .newsletter__button-wrapper {
        position: static;
        margin-top: 3.45rem;
        button {
            padding: 1em;
            text-transform: uppercase;
            background-color: $color-4--1;
            @extend %bg-pattern-dark;
            color: $color-3--3;
            font-family: $typo-2;
            position: relative;
            min-width: 150px;
            font-size: 1.6em;
            z-index: 0;
            transition: opacity 0.25s ease;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 200%;
                height: 100%;
                transform-origin: right top;
                z-index: -1;
            }
            &:before {
                background: inherit;
            }
            &:after {
                background-image: linear-gradient($color-4--1 25%, transparent);
            }
            &:hover,
            &:after {
                opacity: 0.9;
            }
        }
        svg {
            width: 16px;
            height: 16px;
            fill: currentColor;
        }
    }
    .newsletter__links {
        margin-top: 3rem;
    }
}
