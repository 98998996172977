.go-to-top {
    margin-top: -4.8em;
    display: block;
    width: 100%;
    position: relative;
    right: 1%;
    bottom: 0;
    z-index: 100;
    //z-index: 7000;
    p {
        text-align: right;
    }
    &-button.button-1 {
        border: none !important;
        background: none !important;
        overflow: hidden;
        padding: 1.5em;
        width: 5rem;
        @include default-icons-absolute-after ('\E01B', 3em, $color-4--2, 50%, inherit, inherit, 50%, 'icons-project');

        &:after {
            text-indent: 0;
            line-height: 0;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%) rotate(15deg);
            margin: 0;
            transition: all 0.3s ease;
        }
        &:before {
            content: "\e015";
            z-index: 5;
            color: $color-white;
        }
        &:focus,
        &:hover {
            &:after {
                color: $color-4--3;
            }
        }
    }

    .list-infos__buttons .carousel-galerie__pager .button-1--no-text {
        overflow: hidden;
    }
}
