// @name Comments
.comments {
    margin: 2em 0 3em;
}

.comments__title {
    $comments__title__font-size: 2;
    font-size: #{$comments__title__font-size}em;
    color: $color-black;
    font-weight: $bold;
    margin: 0 0 em(2, $comments__title__font-size);
    @include default-icons-before('\e02e', 0 .5rem 0 0, 1.6rem, $color-2--2, -.2rem);
}

.comment {
    margin: 2em 0;
}

.comments__listitems {
    margin: 0 0 3em;
}

.comments__listitems--children {
    .comment {
        padding-left: 100px;
    }
    .comment__wrapper-2 {
        background: $color-white;
        &:after {
            border-color: transparent $color-white transparent transparent;
        }
    }
}

.comment__avatar {
    float: left;
    margin-right: 1em;
    svg {
        width: em(4.3, 1);
        height: em(4.3, 1);
    }
}

.comment__wrapper {
    overflow: hidden;
    padding-left: 10px;
}

.comment__wrapper-2 {
    background: $color-3--1;
    border: 1px solid $color-3--3;
    padding: 2em;
    position: relative;
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        right: 100%;
        width: 0;
        height: 0;
        border-style: solid;
    }
    &:before {
        top: 19px;
        border-color: transparent $color-3--3 transparent transparent;
        border-width: 12px;
    }
    &:after {
        top: 21px;
        border-color: transparent $color-3--1 transparent transparent;
        border-width: 10px;
    }
}

.comment__header {
    margin-bottom: 1.5em;
}

.comment__author {
    font-size: 1.2em;
    font-weight: $bold;
    font-style: normal;
    color: $color-3--2;
    a {
        color: $color-3--2;
    }
    + .date {
        &:before {
            content: " - ";
            display: inline;
        }
    }
}

.comments .comment__body {
    > * {
        margin-bottom: 0;
    }
}

.comment__reply-link {
    position: absolute;
    top: 20px;
    right: 20px;
    p {
        text-align: right;
        text-transform: uppercase;
        font-family: $typo-2;
        @include default-icons-before('\e027', 0 0 0 0, 1rem, $color-3--2, -.1rem);
        a {
            color: $color-3--2;
            font-size: 1.2em;
        }
    }
}

.comment__reply {
    margin: 5em 0 2em;
}

.comment__reply__title {
    font-size: 2em;
    color: $color-black;
    font-weight: $bold;
    margin-bottom: em(2, 2);
}

.comment__reply__avatar {
    $label-font-size: 1.4;
    margin-bottom: 2.5em;
    > div {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
    }
    legend {
        margin: .5em 0 .5em;
        font-size: 1.4em;
    }
    label {
        display: inline-block;
        position: relative;
        line-height: em(1.4, $label-font-size);
        margin: em(0.1, $label-font-size) 0;
        padding: em(1.6, $label-font-size);
        background: $color-3--1;
        svg {
            width: em(4.3, $label-font-size);
            height: em(4.3, $label-font-size);
        }
    }
    input[type=radio] {
        position: absolute;
        left: -9999px;
        &:checked + label {
            background: $color-1--2;
            svg {
                fill: $color-white;
            }
        }
        &:focus {
            & + label {
                outline: 1px dashed $color-1--2;
                outline-offset: 2px;
            }
        }
    }
}
