// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    text-align: center;
    color: $color-1--3;
    .bloc-contact__item {
        margin-bottom: 2em;
    }
    .list-infos-wrapper {
        font-size: 1.4em;
        max-width: 170px;
        margin: 0 auto;
        a {
            color: inherit;
        }
    }
    .list-infos_title {
        h2 {
            font-size: em(2, 1.4);
        }
    }
    .list-infos__item {
        display: table;
        margin: 0.5em auto;
        font-size: 1em;
        &::before {
            color: $color-4--2;
        }
        &.list-infos__address {
            &:before {
                display: none;
            }
        }
        &.list-infos__phone {
            font-size: em(1.7, 1.4);
            a {
                display: block;
            }
        }
        &.list-infos__no-icon {
            font-size: 1em;
            &:before {
                content: none;
            }
            a {
                display: block;
                font-weight: $bold;
            }
        }
    }
    .button-1 {
        width: 170px;
        text-align: center;
        display: block;
        margin: em(1, $button-1__font-size) auto 0;
    }
}
