.menu-user {
    &-opener {
        &-inner {
            padding: 10px 40px 10px 62px;
            &:after {
                right: 23px;
            }
        }
        &-ico {
            left: 31px;
        }
    }
    &-slide {
        width: 316px;
    }
}
