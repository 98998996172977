// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    text-align: left;
    .bloc-contact__wrapper {
        display: flex;
        align-items: center;
    }
    .list-infos {
        flex-grow: 1;
        display: flex;
        align-items: center;
        &-wrapper:first-child {
            margin: 0;
        }
    }
    .list-infos__item {
        display: block;
        &.list-infos__address {
            padding: 0;
        }
    }
    .button-1 {
        margin: 0 0 0 1rem;
    }
}
