// @name Agenda
// @description Sections content below the content

.block-agenda {

    $this: &;

    .wrapper-main {
        padding: 0 1em;
    }

    &__wrapper {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;

        .agenda-item {
            width: 100%;
            margin: 1em 0;
        }
    }
    &--home#{$this} {
        padding-top: 3em;
        padding-bottom: 6em;
        #{$this}__footer {
            text-align: center;
        }
        #{$this}__footer-proposer-btn {
            padding: 1em 1.5em;
            font-size: 1.5em;
            transform: translateY(50%);
        }
    }
}

.agenda-item {
    $this: &;
    &__img {
        height: auto;
        &-empty {
            padding-bottom: 4.5%;
        }
    }
    &__wrap {
        .block-agenda--home & {
            padding-top: 2rem !important;
        }
    }
    &__date {
    }
    .date-1 {
        padding: 1em 0 1em;
        .day {
            font-size: 2.6em;
        }
        .month {
            font-size: 1.6em;
        }
    }
    &__category {
        &--short {
            margin-top: 2rem;
            max-width: initial;
        }
    }
    &--focus {
        #{$this}__wrap {
            padding: 8.5rem 4rem 3rem !important;
        }
        #{$this}__date {
            transform: none !important;
        }
        .agenda__order-btn {
            top: 2em !important;
        }
    }
    .agenda__order-btn {
        top: calc(100% + 2em);
    }
}
