.home-convenient {
    .section-main__wrapper {
        padding-top: 0;
    }
    .title-3 {
        text-align: center;
    }
    .home-convenient__item {
        .home-convenient-section {
            width: 100%;
            padding-right: 0 !important;
            margin-bottom: 2rem;
        }
    }
    .home-convenient-rapides {
        li {
            width: (100% / 2);
        }
    }

    &-signaler {
        &__wrap {
            flex-direction: column;
            padding-left: 4rem;
            text-align: center;
            &:after {
                left: 7rem;
            }
            span {
                position: absolute;
                top: 1rem;
                left: 1rem;
            }
            p {
                margin-top: 1em;
                margin-bottom: 1em;
            }
        }
    }
}
