.home-gallery {
    background-color: $color-3--3;
    overflow: hidden;
    position: relative;
    .home-gallery__title {
        background-color: $color-3--3;
        color: $color-white;
        i {
            color: inherit;
        }
    }
    .heading-block {
        margin-bottom: 4em;
        &:after {
            @extend %bg-pattern-light;
        }
    }
    .image-gallery {
        display: flex;
        flex-wrap: wrap;
        .listItems {
            width: 100%;
            display: none;
        }
        &__lg,
        &__sm {
            width: (100% / 2);
        }
        &__sm {
            display: flex;
            flex-wrap: wrap;
            .image-gallery-item {
                width: 50%;
            }

        }
    }
    &__footer {
        margin: 3em 0;
        .button-1 {
            border-color: $color-white;
            color: $color-white;
            &:hover,
            &:focus {
                border-color: $color-1--2;
                background-color: $color-1--2;
            }
        }
    }
}
.image-gallery-item {
    color: $color-black;
    text-decoration: none;
    position: relative;
    display: block;

    $this: &;

    &#{$this}--video {
        &:after,
        &:before {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            left: 50%;
            top: 50%;
            text-align: center;
            z-index: 1;
            visibility: visible;
            opacity: 1;
            transition: all 0.25s ease;
        }
        &:before {
            content: '\e01b';
            font-family: 'icons-project';
            font-size: 4.5em;
            color: $color-4--1;
            transform: translate(-50%, -50%) rotate(15deg);

        }
        &:after {
            content: '\e075';
            font-family: 'icons-default';
            color: $color-1--3;
            font-size: 2em;
            transform: translate(-50%, -50%);
        }
    }

    &__img {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        padding-bottom: 76%;
        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;' //object-fit polyfill
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($color-black, 0.7);
            opacity: 0;
            transition: all 0.25s ease;
        }
    }

    &__content {
        color: $color-white;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        //transform: translate(-50%, -50%);
        transform: translate(-50%, -25%);
        width: 90%;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s ease;
        white-space: normal;
        .image-gallery__sm & {
            width: 98%;
        }
        .category {
            font-size: 1.6em;
            line-height: 1;
            color: $color-white;
            margin-bottom: 0.5em;
            font-weight: $bold;
            text-transform: uppercase;
            font-family: $typo-2;
            .image-gallery__sm & {
                font-size: 1.4em;
            }
        }
        h3 {
            font-weight: $light;
            font-size: 1.2em;
        }
        p {
            font-size: 2.4em;
            font-weight: $light;
            margin: 1rem 0;
            i {
                font-size: em(1.4, 2.4);
            }
            .button-1 {
                font-size: em(1.2, 2.4);
                color: $color-4--1;
                border-color: $color-4--1;
                &:before {
                    left: 1.5rem;
                    content: "\e04b";
                }
                &:hover {
                    color: $color-black;
                    background-color: $color-4--1;
                }
            }
            .image-gallery__sm & {
                font-size: 1.4em;
                i {
                    font-size: 1em;
                }
                .button-1 {
                    font-size: em(1.2, 1.4);
                }
            }
        }
    }

    &:focus,
    &:hover {
        text-decoration: none;
        &:after,
        &:before {
            opacity: 0;
            visibility: hidden;
        }
        #{$this}__img {
            &:after {
                opacity: 1;
            }
        }
        #{$this}__content {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
        }
    }

}
