// @name Organigrame
// @description Sections content below the content

.block-organigrame {
    &__wrapper {
        margin-left: 0;
        margin-right: 0;
        .organigrame-item {
            width: 100%;
            margin: 1em 0;
        }
    }
}
