.heading-block {
    &--icon {
        i {
            font-size: 3.3rem;
        }
        h2,
        h3 {
            &:before,
            &:after {
                height: 0.85em;
                left: 1.25em;
            }
        }
    }
}

// @name Title 1
// @description Styling for title 1
$title--1__font-size: 3;
.title-1 {
    font-size: #{$title--1__font-size}rem;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 2.5;
.title-2 {
    font-size: #{$title--2__font-size}em;
    margin: em(3.5, $title--2__font-size) 0 em(2.5, $title--2__font-size);
}
