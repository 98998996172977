// @name site informations
.site-infos {
    display: flex;
    align-items: center;
    color: $color-white;
    &__img-wrap {
        img {
            display: block;
            //width: 100%;
        }
    }
    &__wrapper {
        margin: 0 3em;
        font-style: normal;
        text-transform: uppercase;
        p, ul {
            font-size: 1.3em;
            margin: em(.5, 1.3) 0 em(.8, 1.3);
            &:first-child {
                margin-top: 0;
            }
        }
        ul {
            > li {
                margin: 0.2em 0;
            }
        }
        a {
            color: inherit;
            &:hover,
            &:focus {
               text-decoration: underline;
            }
        }
        &-addr {
            strong {
                display: block;
                font-size: em( 1.8, 1.3);
            }
        }
        &-tel {
            a {
                font-size: em(2.6, 1.3);
                font-weight: $bold;
                white-space: nowrap;
                @include default-icons-before('\e02b', 0 .5rem 0 0, 0.85em, $color-4--2, -.1rem);
            }
        }
    }
    .button-1 {
        white-space: nowrap;
        color: $color-white;
        border-color: $color-white;
        padding: em(2, $button-1--big__font-size) em(4, $button-1--big__font-size) em(2, $button-1--big__font-size) em(5, $button-1--big__font-size);
        &:before {
            content: '\e02a';
            left: em(1.5, $button-1--big__font-size);
        }
        &:hover, &:focus {
            border-color: $color-3--3;
        }
    }
}
