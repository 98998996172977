.home-espace {
    &__left {
        width: 100%;
        &-1,
        &-2 {
            width: 49%;
            float: left;
        }
        &-2 {
            padding-left: 2%;
            margin: 0;
        }
    }
    &__right {
        width: 100%;
        padding: 0;
    }

}
