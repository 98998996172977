.block-kiosque-newlsetter {
    //width: 100%;
    //position: relative;
    //padding: 3rem 0 0;
    //&__wrap {
    //    display: flex;
    //    margin-top: 4rem;
    //}
}

.block-kiosque {
    width: 100%;
    padding: 0;
    .bloc-publications {
        flex-wrap: wrap;
        &__picture {
            max-width: 140px;
        }
    }
    &__big,
    &__small {
        width: 100%;
        margin: 0;
    }
    .link-view__feature {
        text-align: center;
    }
}

.block-newlsetter {
    width: 100%;
    margin-left: -1rem;
    &__wrap {
        padding: 1rem 0 1rem 6rem;
        &:before {
            right: calc(100% - 10rem);
        }
    }
}
