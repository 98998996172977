//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
// content in click and roll
.rte.ddm__sub-level > *:first-child,
.ddm__sub-level span.anchor:first-of-type + .colPos18181 .ce-bodytext > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.4;
.rte h2, .rte .h2, .h2 {
    font-family: $typo-1;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $bold;
    color: $color-2--2;
    margin: em(6, $content-h2__font-size) 0 em(2.5, $content-h2__font-size) 0;
    a {
        color: inherit;
        font-weight: inherit;
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3, .rte .h3, .h3 {
    font-family: $typo-1;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $thin;
    color: $color-1--2;
    margin: em(5, $content-h3__font-size) 0 em(1.7, $content-h3__font-size) 0;
    a {
        color: inherit;
        font-weight: inherit;
    }
    &:after {
        content: '';
        display: block;
        margin-top: 0.25em;
        width: 100%;
        height: 9px;
        @extend %bg-pattern-dark;
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4, .rte .h4, .h4 {
    font-family: $typo-1;
    font-size: #{$content-h4__font-size}em;
    line-height: 1.2;
    color: $color-3--2;
    margin: em(4, $content-h4__font-size) 0 em(1.8, $content-h4__font-size) 0;
    a {
        color: inherit;
        font-weight: inherit;
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.rte h5, .rte .h5, .h5 {
    font-family: $typo-2;
    font-size: #{$content-h5__font-size}em;
    line-height: 1.2;
    color: $color-1--3;
    margin: em(4, $content-h5__font-size) 0 em(1.8, $content-h5__font-size) 0;
    a {
        color: inherit;
        font-weight: inherit;
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6, .rte .h6, .h6 {
    font-family: $typo-2;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-1--3;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: inherit;
        font-weight: inherit;
    }
}

$content-list__font-size: 1.4;
.rte {
    // @name Link
    a {
        color: $color-1--3;
        font-weight: $bold;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e06d', 0 0 0 .5rem, 1rem, currentColor, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.6;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $normal;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        $encadre__font-size: 1.5;
        font-size: #{$encadre__font-size}em;
        margin: em(2.5, $encadre__font-size) 0 em(2.5, $encadre__font-size);
        padding: em(2, $encadre__font-size) em(1.5, $encadre__font-size) em(2, $encadre__font-size) em(16, $encadre__font-size) ;
        position: relative;
        background-color: $color-2--2;
        @extend %bg-pattern-dark;
        color: $color-white;
        overflow: hidden;
        z-index: 0;
        a {
            color: inherit;
        }
        @include default-icons-absolute-before('\E01F', 5rem, $color-white, 50%, inherit, inherit, 0.75em, 'icons-project');
        &:before {
            transform: translateY(-50%);
            z-index: 10;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100px;
            background-color: $color-2--1;
            height: 100%;
            width: 100%;
            transform: skewX(-24deg);
            z-index: -1;
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 2.1;
    $content-blockquote-icon-1__font-size: 5;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        overflow: hidden;
        @include default-icons-absolute-before('\e071', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-white,  em(1.25, $content-blockquote-icon-1__font-size), inherit, inherit, em(2.25, $content-blockquote-icon-1__font-size));
        &:before {
            z-index: 10;
        }
        &:after {
            content: '\E01B';
            color: $color-4--2;
            font-size: 7rem;
            line-height: 1;
            position: absolute;
            left: 0.175em;
            top: 0.025em;
            transform: rotate(15deg);
            font-family: 'icons-project';
        }
        padding: em(0.5, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(10, $content-blockquote__font-size);
        font-weight: $light;
        font-family: $typo-2;
        margin: em(2.5, $content-blockquote__font-size) 0 em(2.5, $content-blockquote__font-size) 0;
        color: #727272;
        p {
            font-size: 1em;
            font-weight: $light;
        }
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: 2rem 0 1.5rem 2.5rem;
        list-style: none;
        font-size: #{$content-list__font-size}rem;
        color: $color-black;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            @include default-icons-absolute-before('\e017', 1.4rem, $color-4--2, .2rem, inherit, inherit, 0);
            padding-left: 2rem;
            > ul { // for specificity selector
                margin: 0 0 0.2rem;
                list-style: square url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
                padding: .1rem 0 0 1em;
                > li {
                    padding: 0;
                    &:before {
                        content: none;
                    }
                }
                p:first-child {
                    margin-top: 0;
                }
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 0.2rem 1.5rem;
                padding: .1rem 0 .2rem 0;
                > li {
                    padding: 0;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: 2rem 0 1.5rem 2.5rem;
        padding: 0 0 0 1.8rem;
        list-style: decimal;
        font-size: #{$content-list__font-size}rem;
        color: $color-black;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding: 0;
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 0.2rem 1.5rem;
                padding: .1rem 0 .1rem 0;
                > li {
                    padding: 0;
                }
            }
            > ul:first-child, > a + ul { // for specificity selector
                margin: 0 0 0.2rem;
                list-style: square url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
                padding: .1rem 0 0 1em;
                > li {
                    padding: 0;
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4em 0;
    }
    .table-wrapper-inner {
        max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
            background-color: #e9e9e9;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-1--1;
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-2;
            text-align: left;
            font-weight: $normal;
            font-size: #{$table__caption_font-size}em;
            color: $color-3--2;
            margin-bottom: em(1.5, $table__caption_font-size);
        }
        th, td {
            padding: 1em;
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-family: $typo-3;
            font-weight: $bold;
            color: $color-1--3;
            text-align: left;
        }
        thead  th {
            font-size: 1.4em;
            background: $color-white;
            font-weight: $bold;
            color: $color-1--2;
            border-top: 5px solid $color-1--1;
            border-bottom: 5px solid $color-1--1;
        }
        tbody th {
            text-align: right;
        }
        td {
            font-size: 1.3em;
            color: $color-black;
            background: $color-white;
            &:not(:first-child) {
                border-left: 1px solid $color-3--1;
            }
        }
        tr:nth-child(2n) td,
        tr:nth-child(2n) th {
            //background: darken($color-white, 1%);
            @extend %bg-pattern-dark;
        }
        tr:nth-child(2n-1) td,
        tr:nth-child(2n-1) th {
            padding: 1em;
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}
