// @name block downloads

// @name Block download in go-so-far
.block-download {
    margin-bottom: 3rem;
    &__wrapper {
        background-color: $color-3--4;
        padding: 2rem 1rem;
        font-size: 1.6em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 3rem;
            left: 50%;
            width: 1px;
            height: calc(100% - 6rem);
            background-color: $color-3--1;
        }
    }
    &__item {
        display: block;
        position: relative;
        padding: 1.5rem 2rem 1.5rem 7rem;
        width: 50%;
        text-decoration: none;
        color: $color-3--2;
        i {
            position: absolute;
            left: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 4rem;
            color: $color-1--3;
            transition: color 0.25s ease
        }
        &:hover {
            text-decoration: none;
            color: $color-1--2;
            i {
                color: $color-1--2
            }
        }
    }
}
