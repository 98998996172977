// @name Main stratis
.menu-stratis {
    $menu-stratis__font-size: 1.2;
    background: $color-1--2;
    @extend .clear-fix;
    font-size: #{$menu-stratis__font-size}em;

    abbr {
        border: 0;
    }
    li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        a {
            display: block;
            padding: em(.8, $menu-stratis__font-size) em(.6, $menu-stratis__font-size);
            text-decoration: none;
        }
    }
    .menu-stratis__level-1 {
        @extend .wrapper-main;
        > li {
            width: auto;
            margin-bottom: 0;
            > a {
                color: color-contrast($color-1--2);
                transition: all ease .3s;
            }
            > a:hover,
            > a:focus,
            &.active > a {
                background: $color-black;
                color: color-contrast($color-black);
            }
        }
    }
    .menu-stratis__level-2 {
        position: absolute;
        z-index: 101;
        top: -9999px;
        left: 0;
        font-size: em(1.2, $menu-stratis__font-size);
        width: em(20, $menu-stratis__font-size);
        background: $color-white;
        border-bottom: 0;
        text-align: left;
        padding: 10px;
        &.show {
            top: em(3.1, $menu-stratis__font-size);
            display: block;
        }
        > li {
            display: block;
            float: none;
            border-bottom: 1px solid $color-3--3;
            &:last-child {
                border-bottom: 0;
            }
            > a {
                font-weight: $normal;
                color: color-contrast($color-white);
                transition: all ease .3s;
            }
            ul,
            &.act ul {
                margin: 0 0 10px 10px;
                background: $color-3--1;
                li {
                    border-bottom: 1px solid $color-white;
                    display: block;
                    a {
                        color: color-contrast($color-3--1);
                    }
                }
            }
            a:hover,
            a:focus,
            &.act > a,
            &.active > a {
                background: $color-1--2;
                color: color-contrast($color-1--2);
            }
        }
    }
}
