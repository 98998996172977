.rte {

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        padding-left: 14rem;
        &:before {
            font-size: 3.2rem;
            left: 1em;
        }
        &:after {
            left: 90px;
        }
    }

    // @name Table
    .table-wrapper {
        position: relative;
    }
    .table-wrapper-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
    }
    table {
        caption {
            text-align: left;
        }
    }
}
