// @name Agenda
// @description Sections content below the content

.block-agenda {
    $this: &;

    background-color: $color-1--3;
    color: $color-white;
    padding: 5em 0 6em;
    position: relative;

    &--home#{$this} {
        background-color: $color-3--4;
        padding-bottom: 9.5em;
        .heading-block {
            color: $color-1--2;
            h2,
            h3 {
                background-color: $color-3--4;
            }
            &:after {
                @extend %bg-pattern-dark;
            }
        }
        #{$this}__footer-proposer-btn {
            padding: 1.5em 2em;
            color: $color-white;
            display: block;
            font-size: 2em;
            right: 0;
            bottom: 0;
            font-family: $typo-2;
            text-transform: uppercase;
            transform: translateY(35%);
            background-color: $color-1--3;
            @include default-icons-before('\e08a', 0 5px 0 0, 1em, currentColor, -.2rem);
            position: absolute;
            transition: background-color 0.25s ease;
            &:after {
                content: '';
                position: absolute;
                z-index: -2;
                top: 0;
                left: 0;
                transform-origin: top left;
                width: 50%;
                height: 100%;
                background: inherit;
                transform: skewX(-24deg);
            }
            &:hover,
            &:focus {
                background-color: $color-1--2;
                text-decoration: none;

            }

            .decor {
                @extend %bg-pattern-dark;
                bottom: 0;
                display: block;
                height: 4rem;
                position: absolute;
                right: -1rem;
                transform: skewX(-24deg);
                width: calc(100% + 4rem);
                z-index: -1;
            }
        }

    }

    .heading-block {
        color: $color-white;
        h2,
        h3 {
            background-color: $color-1--3;
        }
        &:after {
            @extend %bg-pattern-light;
        }
        &--icon {
            h2,
            h3 {
                &:before {
                    background-color: $color-4--2;
                }
                &:after {
                    background-color: $color-1--1;
                }
            }
            i {
                color: currentColor;
            }
        }
    }

    &__title {
        margin-top: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 4em -0.5em 1em;

        .agenda-item {
            width: calc((100% / 3) - 1em);
            margin: 1em 0.5em;
        }
    }
    &:not(#{$this}--home) {
        #{$this}__footer {
            .button-1 {
                color: $color-white;
                border-color: $color-white;
                &:hover,
                &:focus {
                    border-color: $color-3--3;
                }
            }
        }
    }
}


.agenda-item {

    $this: &;

    position: relative;
    background-color: $color-white;
    transition: all 0.5s ease;

    .block-agenda--home & {
        background-color: $color-1--3;
        &:hover {
            background-color: rgba($color-1--3, 0.9);
        }
        #{ $this }__wrap {
            color: $color-white;
            text-align: center;
        }
        #{ $this }__date {
            display: flex;
        }
    }

    &:hover {
        background-color: rgba($color-white, 0.9);
    }
    &__img {
        position: relative;
        display: block;
        height: 50%;
        &-empty {
            width: 100%;
            display: block;
            padding-bottom: 66.75%;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;' //object-fit polyfill
        }
    }
    .agenda__order-btn {
        position: absolute;
        top: 2em;
        right: 2em;
        z-index: 100;
    }
    &__wrap {
        text-align: center;
        color: $color-3--2;
        padding: 3em 4em 3em;
        position: relative;
        &:before {
            content: '';
            display: block;
            background-color: $color-4--1;
            right: 50%;
            top: 0;
            width: 5px;
            position: absolute;
            height: 2.4rem;
            transform: translate(0, -70%) skewX(-25deg);
        }
        * {
            color: inherit;
        }
    }
    &__date {
        position: absolute;
        z-index: 0;
        display: none;
        padding-right: 5rem;
        overflow: hidden;
        top: 0;
        transform: translateY(-70%);
        left: 0;
        .date-1 {
            padding: 2em 0 2em 1em;
            margin: 0;
            color: $color-white;
            z-index: 20;
            &:after {
                content: '';
                position: absolute;
                display: block;
                background-image:
                    linear-gradient($color-1--2, transparent),
                    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKUlEQVQImWP8//8/AzJgZGQ0ZsQiyMDw//9/BqiEMVwGSRDOR1UBFQQA3uYYiqmEqeIAAAAASUVORK5CYII=");
                background-color: $color-1--2;
                width: 100%;
                height: 100%;
                right: 50px;
                top: 0;
                transform: skewX(-24deg) translateX(25px);
                z-index: -1;
            }
            .day {
                font-size: 3.3rem;
            }
            .month {
                font-size: 1.9rem;
            }
            time:last-child {
                padding-right: 0;
            }
        }
    }
    &__category {
        font-size: 1.6em;
        font-weight: $normal;
        &--short {
          margin: 0 auto;
          max-width: 150px;
      }
    }
    &__title {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2em;
        text-transform: none;
    }
    &__link {
        display: block;
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
        }
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    &__time,
    &__place {
        font-size: 1.4em;
        font-style: italic;
    }
    &__desc {
        font-size: 1.4rem;
        margin-bottom: 1em;
    }
    &__place {}

    &--focus {
        &#{$this} {
            width: 100% !important;
        }
        #{$this}__img {
            height: 100%;
        }
        #{$this}__category {
            margin: 0;
        }
        #{$this}__wrap {
            text-align: left !important;
            &:before {
                display: none;
            }
        }
        #{$this}__date {
            transform: none;
            .day {
                font-size: 3.8rem;
            }
            .month {
                font-size: 2.2rem;
            }
        }
        #{$this}__time,
        #{$this}__place {
            padding-left: 1rem;
        }
        #{$this}__time {
            position: relative;
            &:before {
                content: '';
                display: block;
                background-color: $color-4--1;
                left: 0;
                top: 0;
                width: 5px;
                position: absolute;
                height: 2.4rem;
                transform: skewX(-25deg);
            }
        }
        @media (min-width: #{$tablet + 1}px) {
            &#{$this} {// agenda-item--focus.agenda-item  increase specify
                display: flex;
                position: relative;
            }
            #{$this}__img {
                width: 50%;
                position: static;
            }
            #{$this}__wrap {
                width: 50%;
                padding: 8rem 5rem 5rem 16rem !important;
            }
            #{$this}__date {
                overflow: visible;
                height: calc(100% + 30px);
                top: inherit;
                bottom: 0;
                margin: 0;
                padding: 0;
                transform: translate(-50%, 0) !important;
                display: flex;
                align-items: center;
                justify-content: center;
                .date-1 {
                    padding: 2rem 2.5rem;
                    min-width: 190px;
                    &:after {
                        right: 0;
                        transform: skewX(-24deg);
                    }
                    time {
                        padding: 0 1em !important;
                    }
                }
            }
        }
    }
}


.agenda__order-btn {
    display: block;
    i {
        font-size: 2em;
        color: $color-white;
        display: block;
        width: 50px;
        height: 50px;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.75em;
        }
        @include default-icons-absolute-after ('\E01B', 2em, $color-4--2, 50%, inherit, inherit, 50%, 'icons-project');
        &:after {
            z-index: -1;
            transform: translate(-50%, -50%) rotate(15deg);
            transition: all 0.3s ease;
        }
    }
    &:focus,
    &:hover {
        i:after {
            color: $color-4--3;
        }
    }

}
