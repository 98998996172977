// @name Social menu

.menu-social {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, -50%);
    z-index: 150;

    &__item {
        float: left;
        margin-right: 1em;
        width: 56px;
        height: 56px;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        box-shadow: 4px 6px 7px rgba($color-black, 0.15);
        border-radius: 1rem;
        img {
            display: block;
            width: 100%;
            height: auto;
        }

    }
}
