// @name Cross menu

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;

    li {
        vertical-align: top;
        display: block;
        margin: .25em 0;
        &.active a {
            font-weight: $normal;
        }
    }
    a {
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;
        font: $light 1.5em/1.2 $typo-2;
        @include default-icons-before('\e027', 0 .5rem 0 0, 1.2rem, $color-4--2, -.1rem);
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    &-secondary {
        position: relative;
        margin-top: 1em;
        padding-top: 1em;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 1px;
            width: 85px;
            background-color: $color-white;
        }
        a {
            font-weight: $normal;
        }
    }
}
