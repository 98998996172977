.home-banner {
    .item {
        &:before {
            position: relative;
        }
        &:after {
            right: 100%;
        }
        &-wrap {
            display: none;
        }
    }
    &-mobile-push {
        display: block;
        margin: 0 4rem 0;
        min-height: 150px;
    }
    .subtitle,
    .title {
        color: $color-black;
        text-shadow: none;
    }
    .subtitle {
        font-size: 1.8em;
    }
    .title {
        margin: 0.5em 0;
        font-size: 2em;
    }
    .modeplay {
        display: none;
    }
    .prevCarrousel1,
    .nextCarrousel1 {
        margin: 0;
        top: auto;
        bottom: 4em;
        button{
            &:before {
                color: $color-black;
            }
        }
    }
    .prevCarrousel1 {
        left: 0;
    }
    .nextCarrousel1 {
        right: 0;
    }
    .pagerCarrousel1 {
        position: relative;
        left: auto;
        bottom: auto;
        display: table;
        margin: 0 auto;
        transform: translate(0, -50%);
    }
}
