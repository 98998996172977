// CSS object

// icon fonts
.icons-default,
.icons-project {
    content: "\e001";
    font-family: 'icons-default';
    font-weight: $normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    speak: none;
}

.icons-project {
    font-family: 'icons-project';
}

$project-icons: (
    "001-document-doc": "\E001",
    "002-document-jpg": "\E002",
    "003-document-pdf": "\E003",
    "004-document-ppt": "\E004",
    "005-document-unknown": "\E005",
    "006-document-xls": "\E006",
    "007-document-zip": "\E007",
    "008-clip": "\E008",
    "009-file-chart": "\E009",
    "010-file-edit": "\E00A",
    "011-click": "\E00B",
    "012-photo": "\E00C",
    "013-woman": "\E00D",
    "014-persons": "\E00E",
    "015-person-rip": "\E00F",
    "016-computer": "\E010",
    "017-child": "\E011",
    "018-file-check": "\E012",
    "019-person-check": "\E013",
    "020-person-profile": "\E014",
    "021-chat": "\E015",
    "022-home": "\E016",
    "023-heart": "\E017",
    "024-bus": "\E018",
    "025-bookmark": "\E019",
    "026-cone": "\E01A",
    "027-skewed": "\E01B",
    "028-callendar": "\E01C",
    "029-basket": "\E01D",
    "030-actualites": "\E01E",
    "031-pin": "\E01F",
    "032-je-suis": "\E020",
    "033-contact": "\E021",
    "034-kiosque": "\E022"
);

[class^="icons-"] {
    font-family: 'icons-project';
    font-weight: $normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    speak: none;
}

@each $class, $icon in $project-icons {
    .icons-#{$class}:before {
        content: $icon;
    }
}

// Invisible content but accessible
.ghost {
    position: absolute !important;
    top: auto !important;
    left: -9999px !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
}

.hidden {
    display: none;
}

// Clear after element
.clear-fix {
    &:after {
        clear: both;
        display: block;
        content: "";
        overflow: hidden;
        visibility: hidden;
        height: 0;
        speak: none;
    }
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}
