.column-bloc.bloc-publications {
    .bloc-publications__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bloc-publications__picture {
        margin: 0;
    }

    .bloc-publications__wrapper {
        text-align: left;
        margin-left: 2em;
    }

    .list-infos {
        margin: 0 auto;
    }

    .bloc-publications__title {
    }
    .link-view__feature {
        .button-1 {
            margin: 0;
        }
    }
}

.block-kiosque {
    width: 100%;
    padding: 0;
    .link-view__feature {
        text-align: center;
    }
}

.block-newlsetter {
    width: 100%;
    margin-left: -1rem;
    .title-2 {
        margin-bottom: 0;
    }
    &__wrap {
        padding: 1rem 0 1rem 6rem;
        &:before {
            right: calc(100% - 8rem);
        }
    }
    .newsletter__fields {
        display: flex;
        align-items: flex-end;
    }
    .newsletter__fields-wrapper {
        flex-grow: 1;
        .newsletter__field-wrapper {
            width: 100%;
            display: table;
            label,
            input {
                display: table-cell;
            }
            label {
                width: 120px;
            }
            input {
                width: 100%;
            }
        }
    }
    .newsletter__button-wrapper {
        padding-left: 8rem;
        button {
            &:before,
            &:after {
                width: 100%;
                transform: skewX(-24deg);
            }
        }
    }
    .newsletter__links {
        float: right;
        margin: 1rem 2rem 3rem;
    }
}
