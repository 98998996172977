// @name Search box
// @description Search module

.search-box {
    padding-left: 7rem;
    padding-right: 11rem;
    width: calc(100% - 20rem);

}

#search.search-box {
    .ddm__title {
        display: none;
    }
}


.search-box#search-inside {
    //margin-right: 4em;
    background-color: $color-white;
    height: auto;
    width: 100%;
    padding: 4rem 2rem;
    display: block;
    position: relative;

    .search-box__fields-wrapper {
        width: 100%;
        padding: 0;
    }
    .search-box__field-wrapper {
        input {
            border-color: $color-2--1;
            font-size: 1.6em;
        }
    }
    .search-box__button-wrapper {
        padding: 0;
    }

    .search-box__button {
        width: 6rem;
        position: absolute;
        height: 100%;
        margin: 0;
        padding: 0;
        right: -1rem;
        padding-left: 2rem;
        top: 0;
        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    &__button-wrapper {
    }
    &__fields-wrapper {
        padding-right: 0;
    }

    label {
        padding: 0;
        width: 0;
        color: transparent;
        position: absolute;
        left: -9999px;
    }
    // Drop down menu
    .ddm {
        &__title {
            display: none;
        }
        > .ddm__sub-level {
            overflow: hidden;
            display: block;
            z-index: 102;
            position: relative;
            * {
                display: block;
            }
        }
    }
}

.search-box__button.button-1,
.button-1--no-icon.search-btn-outside {
    min-width: initial;
    width: 8rem;
    padding: 1em;

}

.button-1--no-icon.search-btn-outside {
    margin-right: 6rem;
    background: $color-white;
    &:hover,
    &:focus {
        > svg {
            fill: $color-white;
        }
    }
    > svg {
        fill: $color-2--1;
    }
    &:before {
        right: 100%;
        left: initial;
        top: 0;
        height: 100%;
        width: 40px;
        background: #fff linear-gradient(to right, transparent 0%, rgba(186, 194, 196, 0.3) 100%);
        transform: none;


    }
    &:after {
        content: none;
    }


}
