// @name Homepage popin
.fancybox-lock .fancybox-overlay {
    overflow: hidden;
}

.popin-home {
    .fancybox-title {
        display: none;
    }
}
//.popin-home {
.popin {
    .logo {
        margin-left: 0;
    }
}

// @name Fullscren homepage popin
.popin-home--fullscreen {
    &.fancybox-wrap {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
    .fancybox-btn-close {
        position: absolute;
        top: 10px;
        color: $color-white;
        right: 40px;
    }
    .fancybox-title {
        display: none;
    }
    .fancybox-inner {
        width: 100% !important;
    }
    .fancybox-inner, .fancybox-outer, .fancybox-skin {
        height: 100% !important;
    }
}
