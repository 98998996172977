// @name List infos
.list-infos {
    font-family: $typo-1;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        border-left: 1px solid $color-1--2;
        padding-left: 5%;
        margin-left: 5%;
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.4;
    margin: .5em 0;
    font-size: #{$list-infos__item__font-size}em;
    padding-left: 0;
    a {
        color: $color-black;
        @include default-icons-absolute-before('\e065', 1.4rem, $color-1--2, .2rem, inherit, inherit, 0);
        padding-left: em(2, $list-infos__item__font-size);
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    &.list-infos__phone, &.list-infos__fax {
        font-weight: $bold;
        white-space: nowrap;
    }
    &.list-infos__address {
        font-style: normal;
        a:before {
            content: "\e053";
        }
    }
    &.list-infos__info {
        a:before {
            content: "\e07f";
        }
    }
    &.list-infos__website {
        a:before {
            content: "\e089";
        }
    }
    &.list-infos__facebook {
        a:before {
            content: "\e099";
        }
    }
    &.list-infos__phone {
        a:before {
            content: "\e02b";
        }
    }
    &.list-infos__fax {
        a:before {
            content: "\e09f";
        }
    }
    &.list-infos__email {
        a:before {
            content: "\e02a";
        }
    }
    &.list-infos__infos {
        a:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        a:before {
            content: "\e05b";
        }
    }
}
