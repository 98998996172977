// @name block rencontre

// @name Block rencontre in go-so-far
.block-rencontre {
    &__footer {
        margin-top: 2rem;
        text-align: center;
    }
}

.rencontre-item {
    &__name {
        padding: 2rem 4rem 2rem 2rem;
    }
    &__content {
        padding: 2rem;
    }
}
