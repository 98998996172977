.logo {
    //width: 70%;
    span,
    a {
        display: block;
        position: relative;
        padding: 0 1em;
        height: 100%;
    }
    img {

    }
}
