.home-banner {
    position: relative;
    .listItems {
        position: relative;
        &:not(.cycle-initialized) {
            display: none;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 35%;
            width: 30%;
            height: 50px;
            bottom: 0;
            z-index: -1;
            border-radius: 50%;
            box-shadow: 0 0 100px 10px rgba($color-black, 0.25);
        }
    }
    .item {
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }
        &:before {
            left: 0;
            @extend %bg-pattern-dark;
            z-index: 5;
        }
        &:after {
            background-color: rgba($color-1--2, 0.6);
            right: calc(100% - 240px);
            transform: skewX(-24deg);
            transform-origin: right bottom;
            z-index: 1;
        }
        &-wrap {
            position: absolute;
            width: 50%;
            left: 6%;
            z-index: 10;
            top: 40%;
        }
    }
    &-mobile-push {
        display: none;
    }
    .subtitle,
    .title {
        color: $color-white;
        text-shadow: -2px 4px 7px rgba(0, 0, 0, 0.3);
    }
    .subtitle {
        font-family: $typo-2;
        text-transform: uppercase;
        font-size: 2.1em;
        font-weight: $normal;
    }
    .title {
        font-size: 4.2em;
        a {
            color: inherit;
            display: block;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .modeplay {
        position: absolute;
        bottom: 20px;
        right: 40px;
        cursor: pointer;
        z-index: 100;
        @include default-icons-before ('\e075', 0, 1.2em, $color-white, middle);
        &--pause:before {
            content: '\e076';
        }
    }
    .prevCarrousel1,
    .nextCarrousel1 {
        position: absolute;
        z-index: 100;
        top: 50%;
        margin-top: -15px;
        button {
            @include default-icons-before ('\e012', 0, 2em, $color-white, middle);
        }
    }
    .prevCarrousel1 {
        left: 1%;
    }
    .nextCarrousel1 {
        right: 1%;
        button:before {
            content: '\e013';
        }
    }
    .pagerCarrousel1 {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        z-index: 100;
        li {
            float: left;
            &.cycle-pager-active button,
            button:hover {
                background-color: $color-4--1;
                background-image: linear-gradient(-231deg, $color-4--2 0%, $color-4--2 50%, transparent, transparent 100%);

            }
        }
        button {
            $dot-size: 20px;
            margin: 1px;
            display: block;
            height: $dot-size;
            width: $dot-size;
            cursor: pointer;
            border-radius: 50%;
            border: 5px solid $color-white;
            background-color: $color-2--1;
            transition: $transition;
        }
    }
}
