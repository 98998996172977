.block-agenda {
    .wrapper-main {
        padding: 0 2em;
    }

    &__wrapper {
        .agenda-item {
            width: calc(50% - 1em);
        }
    }
    &__footer {
        text-align: center;
    }
}
.agenda-item {
    $this: &;
    &__category {
        &--short {
            max-width: 220px;
        }
    }
    .agenda__order-btn {
        &:before,
        &:after {
            display: none;
        }
    }
    &--focus {
        #{$this}__img {
            position: relative;
            height: 50%;
        }
        #{$this}__wrap {
            padding: 3rem 8rem 3rem 18rem !important;
        }
        //#{$this}__date {
        //    transform: none;
        //}
    }
}
