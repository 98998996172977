// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding: 3em 1em;
}

// @name Header page
.header {
    &__wrapper {
        //background-color: $color-2--1;
        margin-left: 5.5rem;
        //height: 6.6rem;
        .menu-main-1__wrapper {
        }
    }
}

.section-main__wrapper-2 {

}

// @name Footer
.footer {
    .footer-wrapper {
        padding: 6em 2em;
    }

    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 auto 2em;
            &:not(:last-child) {
                margin: 0 auto 2em;
            }
        }
    }
}
