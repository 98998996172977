// @name Signature stratis
.stratis {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    font-size: 1.2rem;
    color: $color-white;
    text-align: right;
    z-index: 80001;
    text-transform: uppercase;
    a {
        display: inline-block;
        padding: 0.3rem 1rem;
        color: inherit;
        margin-left: 1rem;
        background: #c70000;
        text-decoration: none;
        &:hover, &:focus {
            color: #c70000;
            background: $color-white;
        }
    }
}
