/*
    Menu REFACTORING
*/

//
// @menu configuration
//

// menu text-colors
$menu-level-1-color: $color-1--2;
$menu-level-2-color: $color-white;
$menu-level-3-color: $color-white;
$menu-level-4-color: $color-white;

// menu text-hover-colors
$menu-level-1-hover-color: $color-white;
$menu-level-2-hover-color: $color-4--2;
$menu-level-3-hover-color: $color-4--2;
$menu-level-4-hover-color: $color-4--2;

// menu text-current-colors
$menu-level-1-current-color: $color-4--2;

// menu text-active-colors
$menu-level-1-active-color: $color-4--2;
$menu-level-2-active-color: $color-4--2;
$menu-level-3-active-color: $color-4--2;
$menu-level-4-active-color: $color-4--2;

// menu backgrounds
$bg-menu-wrapper: transparent;
$bg-dropdown-color: none;
$bg-level-0: $color-white;
$bg-level-1: $color-1--3;
$bg-level-2: none;
$bg-level-3: $color-1--2;
$bg-level-4: $color-1--2;

// menu wrapper max-height
$bg-max-height: 1000px;

// menu icons
//$menu-close-icon: "\e022";
//$menu-dropdown-icon: "+";
//$menu-dropdown-close-icon: "-";
//$menu-no-dropdown-icon: ">";

// menu icons-size
$menu-icon-width: 6.6rem;
$menu-icon-height: 6.6rem;
$menu-icon-size: 2.3rem;

// menu icons-states
$menu-icon-color-normal: $color-white;
$menu-icon-bg-normal: $color-2--1;
$menu-icon-color-active: $color-white;
$menu-icon-bg-active: $color-white;

//
// Default styles
//

.menu-main-1 {

    $menu: &;

    &__wrapper {
        position: static;
        //z-index: 100;
    }

    .wrapper-main {
        width: 100%;
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    &__sublevel {
        display: none;

        &[aria-hidden="false"] {
            display: block;
        }
    }

    &__listitems {
        text-align: left;

        //
        // Level-0
        //

        &-0 {

            > li {
                position: absolute;
                z-index: 200;
                top: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                //overflow-y: auto;
                transition: transform ease .3s;
                transform: translate(100%, 0);
                width: 100%;
                background-color: $bg-level-0;

                > #{$menu}__header {
                    display: block;
                    //background: $color-white;
                    padding: .8em 0;
                    min-height: $menu-icon-height;
                    text-align: left;

                    > span {
                        display: block;
                        text-transform: uppercase;
                        font-size: 1.9em;
                        font-weight: $bold;
                        color: $color-1--3;
                        padding: 0 8.6rem 0 4rem;
                        text-align: left;
                        line-height: 5rem;
                    }

                    > #{$menu}__action {
                        text-align: left;
                        text-indent: -9999px;
                        white-space: nowrap;
                        position: absolute;
                        top: 0;
                        left: -#{$menu-icon-width};
                        width: $menu-icon-width;
                        height: $menu-icon-height;
                        background-color: $menu-icon-bg-normal;
                        transition: all 200ms ease;

                        &::before {
                            position: absolute;
                            font-family: 'icons-default';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%);
                            content: "\e02f";
                            font-size: 1.3em;
                            text-indent: 0;
                            color: $color-white;
                            line-height: 0;
                            display: block;
                            margin: 0;
                            z-index: 1;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            left: -15px;
                            top: 0;
                            z-index: 0;
                            width: 100%;
                            height: 100%;
                            transform: skewX(-23deg);
                            background-color: $menu-icon-bg-normal;
                        }
                    }
                }

                > #{$menu}__sublevel {
                    display: none;

                    &[aria-hidden="false"] {
                        display: block;
                    }

                    > #{$menu}__closing-button {
                        display: block;
                        top: 0;
                        right: 0;
                    }
                }

                &#{$menu}__item--active {
                    position: fixed;
                    transform: translate(0, 0);

                    > #{$menu}__header {

                        #{$menu}__action {
                            left: 0;
                            top: 0;
                            width: 4rem;
                            background: none;
                            &:before {
                                color: $color-1--3;
                            }
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }

        //
        // Level-1
        //

        &-1 {
            //background: $bg-level-2;

            $level: &;

            > li {
                display: block;
                vertical-align: inherit;
                //border-bottom: 1px solid $color-white;

                > #{$menu}__header {
                    > a {
                        border-bottom: 1px solid $color-1--2;
                    }
                }
                #{$menu}__header {

                    &:before,
                    &:after {
                        content: none !important;
                    }

                    > a {
                        color: $menu-level-1-color;
                        padding: 2.3rem 2.5rem;
                        position: relative;
                        padding-right: $menu-icon-width + 1.5rem;

                        &[aria-expanded="true"] {
                            //&:after {
                            //    content: $menu-dropdown-close-icon;
                            //}
                        }

                        &:hover {
                            //color: $menu-level-1-hover-color;
                            text-decoration: none;
                        }
                    }
                }

                &#{$menu}__item--current {
                    > #{$menu}__header > a {
                        color: $menu-level-1-current-color;
                    }
                }

                &#{$menu}__item--active {
                    > #{$menu}__header > a {
                        border-color: $color-white;
                        color: $menu-level-1-active-color;
                        background-color: $color-1--3;
                        &:after {
                            position: absolute;
                            top: 100%;
                            left: 2em;
                            content: '';
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 8px 8px 0 8px;
                            border-color: $color-white transparent transparent transparent;
                        }
                    }
                }

                > #{$menu}__sublevel {
                    position: static;
                    max-height: inherit;
                    display: none;
                    background-color: $bg-level-1;

                    #{$level}__wrapper {
                        transform: inherit;
                        height: auto;
                        padding: 0;
                        background: $bg-dropdown-color;
                    }

                    // Display level
                    &[aria-hidden="false"] {
                        display: block;
                        max-height: inherit;

                        #{$level}__wrapper {
                            transform: inherit;
                            transition: inherit;
                        }
                    }
                }
            }
        }

        //
        // Level-2
        //

        &-2 {
            width: 100%;
            //border-top: 1px solid $color-white;
            min-height: inherit;
            padding: 1em;

            > li {
                // level 2 and 3

                &:last-child {
                    border-bottom: 0;
                }

                #{$menu}__header {
                    #{$menu}__action {
                        width: $menu-icon-width / 1.5;
                        height: $menu-icon-height / 1.5;
                        line-height: $menu-icon-height / 1.5;
                        transform: translateY(-50%);
                        top: 50%;
                        right: 0;
                        margin-top: 0;

                        &:before {
                            //content: $menu-dropdown-icon;
                            color: $menu-icon-color-normal;
                            font-size: $menu-icon-size;
                            text-align: center;
                        }

                        &[aria-expanded="true"] {
                            //background: $menu-icon-bg-active;

                            &::before {
                                //content: $menu-dropdown-close-icon;
                                //color: $menu-icon-color-active;
                            }
                        }
                    }
                }

                > #{$menu}__header {

                    a {
                        padding: 1.25rem 3rem;
                        padding-right: $menu-icon-width / 1.5 + 1rem;
                        color: $menu-level-2-color;
                        //background-color: $color-black;

                        &:hover {
                            color: $menu-level-2-hover-color;
                        }
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-2-active-color;
                    }
                }

                #{$menu}__sublevel {
                    position: relative !important;
                    z-index: 10;
                    left: 0;
                    &:before {
                        top: 0;
                        left: 3em;
                    }
                }

                > #{$menu}__sublevel {
                    display: none;
                    position: static;
                    width: 100%;
                    transform: inherit;
                    height: 0;
                    transition: inherit;
                    //background-color: $bg-level-2;

                    &[aria-hidden="false"] {
                        display: block;
                        transform: inherit;
                        height: auto;
                        transition: inherit;
                    }


                }

            }

            #{$menu}__closing-button {
                display: none;
            }

        }

        //
        // Level-3
        //

        &-3 {
            padding: 0 0.5rem;

            > li {

                > #{$menu}__header {
                    a {
                        padding: 1.25rem 3rem;
                        margin: 1em 0;
                        padding-right: $menu-icon-width / 2 + 1rem;
                        color: $menu-level-3-color;

                        &:hover {
                            color: $menu-level-3-hover-color;
                        }
                    }
                    #{$menu}__action {
                        width: $menu-icon-width / 2;
                        height: $menu-icon-height / 2;
                        line-height: $menu-icon-height / 2;
                        &:before {
                            //content: $menu-dropdown-icon;
                            color: $menu-icon-color-normal;
                            font-size: $menu-icon-size / 1.5;
                            text-align: center;
                        }

                        &[aria-expanded="true"] {
                        }
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-3-active-color;
                    }
                }

                > #{$menu}__sublevel {
                    display: none;
                    position: static;
                    width: 100%;
                    transform: inherit;
                    height: 0;
                    transition: inherit;
                    background-color: $bg-level-3;

                    &[aria-hidden="false"] {
                        display: block;
                        transform: inherit;
                        height: auto;
                        transition: inherit;
                    }
                }
            }
        }

        //
        // Level-4
        //

        &-4 {
            padding: .5em 0;

            > li {

                #{$menu}__header {

                    a {
                        padding: 1.5rem 4rem;
                        color: $menu-level-4-color;

                        &:hover {
                            color: $menu-level-4-hover-color;
                        }
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-4-active-color;
                    }
                }

                > #{$menu}__sublevel {
                    background-color: $bg-level-4;
                }
            }

        }
    }

    &__item {

        > #{$menu}__header {

            a {
                display: block;
            }
        }

        > #{$menu}__sublevel {
            text-decoration: none;
        }

        &--no-action-in-desktop {

            #{$menu}__sublevel {
                display: none;

                &[aria-hidden="false"] {
                    display: block;
                }
            }
        }

        &--no--button {}
        &--current {}
        &--active {}
    }

    &__button {}
    &__closing-button {}

}

/*
    End REFACTORING
*/

/* Custom styles */
// Prevent page scroll when menu open
body.home.menu-main-1--active {
    overflow: hidden;
}
// Add scroll inside the menu
.menu-main-1__listitems-0 > li.menu-main-1__item--active {
    overflow-y: scroll;
}

/* Custom styles */
