// @name block actualites

// @name Block actualites in go-so-far
.block-actualites {
    margin-bottom: 3rem;
    margin-top: 6rem;
    &__wrapper {
        margin: 0;
        .actualites-item {
            width: 100%;
            margin: 0 0 3em;
        }
    }
    &__footer {
        text-align: center;
    }
}
