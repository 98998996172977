// @name site informations
.footer .site-infos {
    margin-top: 0;
    text-align: center;
    > * {
        margin: 2em 0;
    }
    &__img-wrap {
        img {
            width: auto;
            margin: 0 auto;
        }
    }
}

