.booking-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-1rem);
    transition-duration: 350ms;
    transition-property: max-height, visibility, opacity, transform;
    transition-timing-function: ease-in-out;
    visibility: hidden;

    > *:first-child {
        margin-top: 3rem;
    }

    &.-is-visible {
        max-height: 100rem;
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
}

.booking-info {
    margin: 3rem 0;
}

.booking {
    &__wrapper {
        display: flex;
        margin: 3rem -1rem 0;
        overflow: auto;

        &:empty {
            display: none;
        }
    }

    &__day {
        text-align: center;
    }

    &__list {
        box-sizing: border-box;
        display: block;
        margin: 1rem 0;
        padding: 0 1rem;
        width: 100%;
    }

    &__list-wrap {
        max-height: 30rem;
        overflow: auto;
    }

    &__period {
        @include trs(background-color);
        background-color: $color-3--1;
        border: 0;
        display: block;
        font-size: 1.4rem;
        margin: 1px 0;
        padding: 0.8rem 1.2rem;
        width: 100%;

        @include on-event() {
            background-color: $color-3--4;
            outline-offset: -2px;
        }

        &.-disabled {
            background-color: $color-3--1;
            color: $color-3--2;
            cursor: not-allowed;
        }

        &.-is-active {
            background-color: #a2d9ef;
            font-weight: $bold;
        }
    }

    .js-disabled {
        background-color: $color-3--1;
        color: $color-3--2;
        cursor: not-allowed;
    }
}

.booking-slider {
    $this: &;
    border: 1px dashed $color-3--3;
    margin: 0;
    overflow: hidden;
    padding: 3rem 8rem;
    position: relative;

    &__carousel {
        margin-left: -1rem;
        width: calc(100% + 2rem);
    }

    &__control {
        @include absolute(50%, null, null, null);
        @include size(5rem);
        background-color: transparent;
        border: 0;
        cursor: pointer;
        font-size: 4rem;
        padding: 0;
        transform: translateY(-50%);
        z-index: 10;

        &.-prev {
            @include default-icons-before ('\e01a', 0, 3rem, $color-1--3, middle);
            left: 1rem;
        }

        &.-next {
            @include default-icons-before ('\e01b', 0, 3rem, $color-1--3, middle);
            right: 1rem;
        }

        &::before {
            @include trs();
        }

        @include on-event() {
            &::before {
                color: $color-1--1;
            }
        }

        &[disabled='true'] {
            pointer-events: none;

            &::before {
                color: rgba($color-black, 0.2);
            }
        }
    }

    .swiper-slide {
        @include trs();

        &[aria-hidden='true'] {
            opacity: 0;
            visibility: hidden;
        }

        &[aria-hidden='false'] {
            opacity: 1;
            visibility: visible;
        }
    }
}
