.home-espace {

    $this: &;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    .heading-block--icon .title-1 {
        &:before {
            background-color: $color-1--1;
        }
        &:after {
            background-color: $color-2--1;
        }
    }

    .title-2,
    .title-3 {
        color: $color-3--2;
        font-weight: $normal;
    }
    .title-2 {
        margin-top: 0;
        font-size: 2.6em;
    }

    .title-3 {
        margin-top: .5rem;
        margin-bottom: 1.5em;
        font-size: 1.7em;
    }

    &__left,
    &__right {

    }
    &__left {
        width: 31%;
        &-2 {
            margin: 1.5rem 0;
        }
    }
    &__right {
        padding-left: 4rem;
        width: 69%;
    }

    &__articles {
        display: flex;
        flex-wrap: wrap;
        .espace-item {
            width: 49%;
            &:nth-child(2n) {
                margin-left: 2%;
            }
        }
    }

    &__footer {
        float: right;
        display: block;
        transform: translateY(-40%);
        position: relative;
        z-index: 1;
        a {
            font-family: $typo-2;
            text-transform: uppercase;
            font-size: 1.6em;
            display: block;
            padding: 1.5em;
            background-color: $color-2--2;
            color: $color-white;
            @include default-icons-before('\e08a', 0 5px 0 0, 1.2em, currentColor, -.2rem);
            transition: background-color 0.2s ease;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                transform-origin: top left;
                width: 50%;
                height: 100%;
                background: inherit;
                transform: skewX(-24deg);
            }
            &:hover,
            &:focus {
                text-decoration: none;
                background-color: $color-2--1;
            }
        }
    }

}

.home-espace__form {
    display: flex;
    &-select {
        flex-grow: 1;
        select {
            border: none;
            border-bottom: 1px solid $color-3--2;
            background: $color-white url("data:image/svg+xml,%3Csvg fill='%23FFCC66' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 5'%3E%3Cpath d='M4.5,0.9l-2,3.2l-2-3.2H4.5z'/%3E%3C/svg%3E") no-repeat 98% 50%;
            background-size: 0.65em;
            + select {
                margin-top: 0.5em;
            }
        }
    }
    &-submit {
        margin-left: 1rem;
        align-self: flex-end;
        button {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            display: block;
            @include default-icons-absolute-before('\E01B', 3.8rem, $color-2--2, 0, 0, inherit, inherit, 'icons-project');
            @include default-icons-absolute-after('\E033', 1.5rem, $color-white, 50%, 0.75em, inherit, inherit);
            &:before {
                transform: rotate(15deg);
                transition: all 0.3s ease;
            }
            &:after {
                transform: translateY(-50%);
            }
            &:hover,
            &:focus {
                &:before {
                    color: $color-2--1;
                }
            }
        }
    }
}

.home-espace__links {
    padding: 2rem 1rem;
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%23006699' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 380 133'%3E%3Cpath d='M379.7,20.1c0-10.6-9.6-19.1-21.4-19.1l-336,8.6C10.5,9.6,1,18.2,1,28.8v84.1c0,10.6,9.5,19.1,21.3,19.1h336c11.8,0,21.3-8.6,21.3-19.1L379.7,20.1z'/%3E%3C/svg%3E");
    background-size: 100% 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    li {
        margin: 1rem;
    }
    a {
        color: $color-white;
        font-family: $typo-2;
        display: block;
        text-transform: uppercase;
        font-size: 1.3em;
        line-height: 1.2;
    }
    img {
        display: block;
        width: auto;
        margin: 0 auto 1rem;
        height: auto;
        max-width: 30px;
        max-height: 30px;
    }
}

.espace-item {
    display: flex;
    margin-bottom: 1.5rem;
    &__img {
        flex-shrink: 0;
        margin-right: 2rem;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__cat {
        font-weight: $normal;
    }
    &__title {
        font-size: 1.4em;
        margin: 0.5em 0;
        font-weight: $light;
    }
    &__link {
        color: $color-black;
    }
    &__desc {
        font-size: 1.4em;
        line-height: 1.2;
    }

}
