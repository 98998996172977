.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.25em;
}

.ddm.ddm__content {
    margin: 4em 0 4em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        //transition: all ease .2s;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 1.5em;
            max-height: 100%;
            border: 1px solid $color-3--1;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 2em;
        font-family: $typo-3;
        font-weight: $light;
        background: $color-1--2;
        color: $color-white;
        padding: em(.5, 2) em(4, 2) em(.5, 2) em(1, 2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -10px;
            bottom: 0;
            width: 55px;
            background-color: rgba($color-black, 0.2);
            z-index: 1;
            transform: skew(-24deg);
        }
        @include default-icons-absolute-before('\e08a', 1em, $color-white, 50%, 1rem, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
        &:hover {
            background: $color-1--3;
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-1--3;
            &:before {
                content: "\e023";
            }
        }
    }
}
