.ddm.menu-user {
    align-self: stretch;
    color: $color-2--2;
    font-family: $typo-2;
    text-transform: uppercase;
    .ddm__title {
        font-size: 1.6rem;
        line-height: 1.2;
        position: relative;
        height: 100%;
        display: flex;
        //display: block;
        //transition: background 0.2s linear, border-color 0.2s linear;
        &:after,
        &:before {
            content: '';
            height: 100%;
            position:absolute;
            top: 0;
            transform: skewX(-23deg);
        }
        &:after {
            //background-color: #0ff;
            background: $color-white linear-gradient(to right, transparent 0%, transparent 50%, rgba(186, 194, 196, 0.3) 100%);
            width: 2em;
            right: 100%;
        }
        &:before {
            width: 100%;
            position: absolute;
            left: 0;
            //background-color: #bbdada;
            //background-color: #f00;
            transition: all 0.15s ease;
            z-index: 1;

            //top:-20px;
            //left:-15px;
            //bottom: 15px;
            //pointer-events: none;
            //box-shadow: 9px 0 12px 0 rgba(0, 0, 0, 0.9);
            //background: url('../Images/bg/user-menu-shadow.png') no-repeat 0 0;
            //width: 5px;
            //transform: rotate(6deg);
            //content: '';
        }
        &:hover,
        &:focus {
            //text-decoration: none;
            //background: #890007;
            //border-right-color: #a72d33;
            //.user-menu-opener-ico {
            //    transform: translate(0, 100%);
            //}
        }
    }
    .ddm__button {
        position: relative;
        z-index: 2;
        padding: 1em;
        align-self: center;
        text-transform: inherit;
        color: inherit;
        font-size: 2rem;
        line-height: 1.2;
        font-family: $typo-2;
        @include default-icons-before('\E020', 0 5px 0 0, 1.25em, inherit, middle, 'icons-project');
        @include default-icons-after('\e014', 0 0 0 1rem, 0.5em, inherit, middle);
        &:hover,
        &:focus {
            //outline-offset: -.1rem;
            //outline-color: $color-white;
        }
    }
    .ddm__sub-level {
        //top:100% !important;
        //left: auto !important;
        //right: 0;
        //width: 327px;
        width: calc(100% + 46px);
        left: 0;
        margin-left: -25px;

        top: 100%;
        background-color: #bbdada;
        padding: 1rem 2rem;
        li {
            margin: 15px 0;
        }
        a {
            display: block;
            color: inherit;
            text-transform: uppercase;
            font-size: 1.6em;
            padding: 0 0 0 3.5rem;
            position: relative;
            img {
                width: auto;
                height: auto;
                min-width: 10px;
                min-height: 10px;
                max-height: 30px;
                max-width: 30px;
                position: absolute;
                left: 1.5rem;
                top: 50%;
                transform: translate(-50%, -50%)
            }
        }
        span {}
    }
    &.ddm--active {
        .ddm__title:before {
            background-color: #bbdada
        }
        .ddm__button:after {
            content: '\e015';
        }
        .user-menu-opener {
            //text-decoration: none;
            //background: #890007;
            //border-right-color: #a72d33;
        }
    }
}
