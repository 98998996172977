// @name block actualites

// @name Block actualites in go-so-far
.block-actualites {
    margin-top: 0;
    margin-bottom: 5rem;
    .wrapper-main {
        max-width: 60rem;
    }
    .heading-block {
        margin: 0 0 2em;

    }
    &__wrapper {
        .actualites-item {
            width: calc(50% - 3em);
        }
    }
    &__footer {
        text-align: center;
    }
}
