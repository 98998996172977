// @name Dropdown menu
.ddm.ddm--on .filters__ddm {
    display: inline-block;
    vertical-align: middle;
    .button-1 {
        &:before {
            content: "\e031";
        }
    }
}

.filters .ddm .ddm__sub-level {
    position: static;
    top: inherit;
    right: inherit;
    z-index: inherit;
}

// @name Filters
// @description Filters above list. The Fields are managed with flexbox.
// You can display the fields on 1, 2, 3 or 4 columns.
// On the div element .filters__fields add a good class:
// 1 column: no class, it's the default display.
// 2 columns: add class .filters__fields--column-2
// 3 columns: add class .filters__fields--column-3
// 4 columns: add class .filters__fields--column-4
// By default, label and field are place on top of other. For positionning side by side add the class .filters__wrapper--in-line on .filters__wrapper

.filters {
    margin-top: -4em;
    padding: 0.1em 0 3em 0;
    background: $color-3--4;
    // adapt form
    form {
        margin: 2em 0 0;
    }
    fieldset {
        margin: 0;
    }
    .radio, .checkbox {
        &.column-2 {
            legend + div {
                margin-top: 1.1em;
            }
        }
    }
}

.filters__header {
    text-align: left;
}

.filters__locate {
    &:before {
        content: "\e04d";
    }
}

.filters__event {
    &:before {
        content: "\e009";
    }
}

.filters__wrapper {
    display: table;
    width: 100%;
    // A supprimer ?
    /*
    .filters__field-1, .filters__field-2 {
      display: inline;
      vertical-align: middle;
    }
    */
    .filters__field-2 {

    }
    &.filters__wrapper--in-line {
        .filters__field-wrapper {
            width: 100%;
            .filters__field-1, .filters__field-2 {
                display: table-cell;
            }
            .filters__field-1 {
                text-align: right;
                padding-right: 1em;
                white-space: nowrap;
            }
            .filters__field-2 {
                display: table-cell;
                width: 100%;
            }
        }
        .filters__button-wrapper {
            padding-top: .5em;
        }
    }
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__wrapper {
        table-layout: fixed;
    }
}

.filters__fields, .filters__button-wrapper {
    display: table-cell;
    vertical-align: top;
}

// @name Container of any fields
// @state .filters__fields--column-2 - Indicates 2 columns
// @state .filters__fields--column-3 - Indicates 3 columns
// @state .filters__fields--column-4 - Indicates 4 columns
// @state .filters__fields--in-line - Indicates label and field are side by side. Not works with checkbox and radio.
.filters__fields {
    width: 100%;
    .filters__fields-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 0;
        margin-left: -1%;
        margin-right: -1%;
        > .filters__field-wrapper {
            align-self: flex-end;
            margin: .5em 1% 1.5em;
            flex: 1 0 100%;
        }
    }

    &.filters__fields--column-2 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 48%;
            }
        }
    }
    &.filters__fields--column-3 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 31.3333%;
            }
        }
    }
    &.filters__fields--column-4 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 23%;
            }
        }
    }
}

.filters__button-wrapper {
    padding-left: 2em;
    padding-top: 2.875em;
    white-space: nowrap;
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__button-wrapper {
        width: 25em;
    }
}

.filters__reset {
    &:before {
        content: "\e058";
    }
}

.button-1.filters__submit {
    &:before {
        content: "\e031";
    }
}

// @name Filters dark
// @description Filters with dark backhround
.filters__dark {
    background: $color-3--2;
}
