.parallax {
    height: 500px;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @extend %bg-pattern-dark;
    }
}
