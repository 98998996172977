/*
    Menu REFACTORING
*/

//
// @menu configuration
//

// menu text-colors
$menu-level-1-color: $color-1--2;
$menu-level-2-color: $color-white;
$menu-level-3-color: $color-white;
$menu-level-4-color: $color-white;

// menu text-hover-colors
$menu-level-1-hover-color: $color-4--2;
$menu-level-2-hover-color: $color-4--2;
$menu-level-3-hover-color: $color-2--1;
$menu-level-4-hover-color: $color-2--1;

// menu text-current-colors
$menu-level-1-current-color: $color-4--2;

// menu text-active-colors
$menu-level-1-active-color: $color-white;
$menu-level-2-active-color: $color-4--2;
$menu-level-3-active-color: $color-2--1;
$menu-level-4-active-color: $color-2--1;

// menu backgrounds
$bg-menu-wrapper: transparent;
$bg-dropdown-color: rgba($color-1--3, 0.95);
//$bg-level-2: $color-1--3;
//$bg-level-3: $color-1--3;
//$bg-level-4: $color-1--3;

// menu wrapper max-height
$bg-max-height: 1000px;

// menu icons
$menu-close-icon: "\e022";
$menu-dropdown-icon: "\e08a";
$menu-dropdown-close-icon: "\e023";
$menu-no-dropdown-icon: ">";

// menu icons-size
$menu-icon-width: 2.5rem;
$menu-icon-height: 2.5rem;
$menu-icon-size: 1.3rem;

// menu icons-states
$menu-icon-color-normal: $color-white;
$menu-icon-bg-normal: $color-1--2;
$menu-icon-color-active: $color-white;
$menu-icon-bg-active: $color-1--1;

//
// Default styles
//

.menu-main-1 {

    $menu: &;

    &__wrapper {
        //width: calc(100% - 55em);
        margin-left: 1em;
        margin-right: 1em;
        font-family: $typo-2;
        background-color: $bg-menu-wrapper;
    }

    &__sublevel {
        display: none;

        &[aria-hidden="false"] {
            display: block;
        }
    }

    &__listitems {
        //text-align: right;

        //
        // Level-0
        //

        &-0 {

            > li {

                > #{$menu}__header {
                    display: none;
                }

                > #{$menu}__sublevel {
                    display: block;

                    > #{$menu}__closing-button {
                        display: none;
                    }
                }
            }
        }

        //
        // Level-1
        //

        &-1 {

            $level: &;

            > li {
                display: inline-block;
                vertical-align: middle;

                > #{$menu}__header {

                    > a {
                        color: $menu-level-1-color;
                        font-weight: $normal;
                        font-size: 1.7rem;
                        padding: 1.5rem;
                        transition: all 200ms ease;
                        text-transform: uppercase;

                        &:focus,
                        &:hover {
                            color: $menu-level-1-hover-color;
                            text-decoration: none;
                        }
                    }
                }

                > #{$menu}__item--current {

                     > #{$menu}__header > a {
                         color: $menu-level-1-current-color;
                     }
                 }

                &#{$menu}__item--has-items {
                    > #{$menu}__header {
                        position: relative;
                        &:after,
                        &:before {
                            content: '';
                            position: absolute;
                            display: block;
                            width: 5px;
                            height: 24px;
                            bottom: -45px;
                            left: 50%;
                            z-index: 201;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.5s ease;
                        }
                        &:after {
                            transform: translate(-85%, 15%) skewX(-25deg);
                            background-color: $color-4--2;
                        }
                        &:before {
                            transform: translate(85%, -15%) skewX(-25deg);
                            background-color: $color-2--1;
                        }

                    }
                    > #{$menu}__header > a {
                        @include default-icons-absolute-before('\e027', 1.2rem, $color-4--2, 50%, inherit, inherit, 0.25rem);
                        &:before {
                            transform: translateY(-50%) rotate(90deg);
                            transition: transform 0.1s ease;
                        }
                    }
                }

                &#{$menu}__item--active {
                    > #{$menu}__header {
                        &:after,
                        &:before {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    > #{$menu}__header > a {
                        color: $menu-level-1-active-color;
                        background-color: $color-1--3;
                        &:before {
                            transform: translateY(-50%) rotate(-90deg);
                        }
                    }
                }

                // Level 1, 2, 3, 4
                #{$menu}__sublevel {
                    display: block;
                    position: absolute;
                    visibility: hidden;
                    //transition: transform ease .2s, visibility ease 0s .2s;

                }

                // Level 1
                > #{$menu}__sublevel {
                    //display: block;
                    //position: absolute;
                    z-index: 200;
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    max-height: 0;
                    //visibility: hidden;
                    overflow: hidden;
                    transition: visibility ease .2s, max-height ease .2s;

                    #{$level}__wrapper {
                        transform: translateY(-110%);
                        transition: transform ease-in .2s;
                        height: 100%;
                        background: $bg-dropdown-color;
                    }

                    // Display level
                    &[aria-hidden="false"] {
                        visibility: visible;
                        max-height: $bg-max-height;

                        #{$level}__wrapper {
                            transition: transform ease-out .4s .2s;
                            transform: translateY(0);
                        }
                    }

                    a, button, p {
                        color: $color-white;

                        &:focus {
                            outline-color: $color-white;
                        }
                    }
                }
            }
        }

        //
        // Level-2
        //

        &-2 {
            width: 39rem;
            min-height: 35rem;
            position: relative;
            padding: 4em 3em;
            height: auto;
            transition: height ease .3s;
            text-align: left;

            > li {
                display: block;
                //border-bottom: 1px solid $color-white;

                // level 2, 3, 4
                #{$menu}__header {
                    position: relative;

                    a {
                        @include default-icons-absolute-before('\e027', 1.2rem, $color-4--2, 50%, inherit, inherit, 0.75rem);
                        &:before {
                            transform: translateY(-50%);
                            transition: transform 0.1s ease;
                        }
                    }

                    #{$menu}__action {
                        position: absolute !important;
                        top: 50%;
                        right: 0;

                        bottom: 0;
                        margin-top: - ($menu-icon-height / 2);
                        text-align: left;
                        text-indent: -9999px;
                        white-space: nowrap;
                        width: $menu-icon-width;
                        height: $menu-icon-height;
                        background: $menu-icon-bg-normal;
                        overflow: hidden;
                        flex-basis: 3rem;

                        @include default-icons-absolute-before(
                                $menu-dropdown-icon,
                                $menu-icon-size,
                                $menu-icon-color-normal,
                                50%,
                                inherit,
                                inherit,
                                50%
                        );

                        &:before {
                            //position: absolute;
                            //top: 50%;
                            //left: 50%;
                            transform: translate(-50%);
                            //content: $menu-dropdown-icon;
                            //color: $menu-icon-color-normal;
                            //font-size: $menu-icon-size;
                            //font-weight: $bold;
                            text-indent: 0;
                            line-height: 0;
                            //display: block;
                            //margin: 0;
                        }

                        &[aria-expanded="true"] {
                            background: $menu-icon-bg-active;

                            &::before {
                                content: $menu-dropdown-close-icon;
                                color: $menu-icon-color-active;
                            }
                        }
                    }
                }

                // level 2
                > #{$menu}__header {

                    a {
                        text-transform: uppercase;
                        font-size: 1.6em;
                        padding: 0.5rem 2.5rem;
                        text-align: left;
                        font-weight: $normal;
                        padding-right: $menu-icon-width + 1.5rem;
                        color: $menu-level-2-color;

                        &:hover {
                            color: $menu-level-2-hover-color;
                        }
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-2-active-color;
                        &:before {
                            left: 1.25rem;
                        }
                    }
                }

                // level 2, 3, 4
                #{$menu}__sublevel {
                    top: 0;
                    min-height: 100%;
                    left: 100%;
                    width: calc(118rem - 78rem);
                    transform: scaleX(0);
                    transform-origin: 0 0;
                    //padding: 3em 2em;
                    background: linear-gradient(to right, rgba($color-1--2, 0.3) 0%, transparent 25%, transparent 100%);
                    transition: transform ease .2s, visibility ease 0s .2s;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 5px;
                        height: 24px;
                        top: 50%;
                        background-color: $color-4--2;
                        transform: translate(-50%, -50%) skewX(-25deg);
                    }
                }

                // level 2
                > #{$menu}__sublevel {
                    //display: block;
                    //position: absolute;
                    //left: 100%;
                    //top: 0;
                    //width: calc(118rem - 78rem);
                    //visibility: hidden;
                    //transform: scaleX(0);
                    //transform-origin: 0 0;
                    //background-color: $bg-level-2;

                    &[aria-hidden="false"] {
                        visibility: visible;
                        transform: scaleX(1);
                        transition: transform ease .4s, -webkit-transform ease .4s;
                    }
                }

            }

            #{$menu}__closing-button {
                display: none;
            }

        }

        //
        // Level-3
        //

        &-3 {
            //padding: 0 1em;
            padding: 4em 3em;
            text-align: left;

            > li {

                //Level 3, 4
                #{$menu}__header {
                    a {
                        font-family: $typo-1;

                        &:before {
                            color: $color-2--1;
                        }

                        &:hover {
                            color: $menu-level-3-hover-color;
                        }
                    }
                }

                //Level 3
                > #{$menu}__header {
                    a {
                        font-size: 1.5em;
                        padding: 0.5rem 2.5rem;
                        font-weight: $normal;
                        padding-right: $menu-icon-width + 1.5rem;
                        //color: $menu-level-3-color;

                        //&:hover {
                        //    color: $menu-level-3-hover-color;
                        //}
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-3-active-color;
                    }
                }

                //Level 3, 4
                #{$menu}__sublevel {
                }
                //Level 3
                > #{$menu}__sublevel {
                    //display: block;
                    //position: absolute;
                    //left: 100%;
                    //top: 0;
                    //width: calc(118rem - 78rem);
                    //visibility: hidden;
                    //transform: scaleX(0);
                    //transform-origin: 0 0;
                    //transition: transform ease .2s, visibility ease 0s .2s;
                    //background-color: $bg-level-3;

                    &:before {
                        background-color: $color-2--1;
                    }

                    &[aria-hidden="false"] {
                        transition: transform ease .4s, visibility ease 0s 0s;
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }
            }
        }

        //
        // Level-4
        //

        &-4 {
            padding: 4em 3em;
            //background-color: $bg-level-4;
            text-align: left;

            > li {
                margin: 0;

                #{$menu}__header {

                    a {
                        font-size: 1.4em;
                        font-weight: $light;
                        padding: 0.25rem 2.5rem;
                        //color: $menu-level-4-color;

                        //&:hover {
                        //    color: $menu-level-4-hover-color;
                        //}
                    }
                }

                &#{$menu}__item--active {

                    > #{$menu}__header > a {
                        color: $menu-level-4-active-color;
                    }
                }

                > #{$menu}__sublevel {
                    //background-color: $bg-level-4;
                }
            }

        }
    }

    &__item {

        > #{$menu}__header {

            a {
                display: block;
                text-decoration: none;
            }

        }

        > #{$menu}__sublevel {
            text-decoration: none;
        }

        &--no-action-in-desktop {

            #{$menu}__sublevel {
                display: block;
            }
        }

        &--no--button {}
        //&--current > div.menu-main-1__header > a {
        //    text-decoration: underline;
        //    color: $color-4--2;
        //}
        &--active {}
    }

    &__button {}

    &__closing-button {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: absolute;
        top: .5rem;
        right: .5rem;
        width: 6.6rem;
        height: 6.6rem;
        background: transparent;
        display: none;

        &:before {
            position: absolute;
            font-family: 'icons-default';
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            content: $menu-close-icon;
            font-size: 1.5em;
            color: $color-1--1;
            font-weight: $bold;
            text-indent: 0;
            line-height: 0;
            display: block;
            margin: 0;
        }
    }

}

/*
    End REFACTORING
*/

/* Custom styles */

body {
    &.menu-open {
        overflow-y: hidden;
    }
}

/* Custom styles */
