// @name site informations
.footer .site-infos {
    display: block;
    margin-top: -10em;
    &__wrapper {
        margin: 2em 0;
        &-tel {
            a {
                font-size: em(2.1, 1.3);
            }
        }
    }
}
