// # Global
$domain: "guipavas.bzh";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,700";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";
$import-google-font-3: "https://fonts.googleapis.com/css?family=Open+Sans:300,700";

$typo-1: 'Montserrat', sans-serif;
$typo-2: 'Oswald', sans-serif;
$typo-3: 'Open Sans', sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

$transition: all 0.25s ease-in-out;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #59b3d9;
$color-1--2: #0085bd;
$color-1--3: #005a93;

// ## color-2
$color-2--1: #56a3a3;
$color-2--2: #007575;
$color-2--3: #004c4d;

// ## Color-3
$color-3--1: #bac2c4;
$color-3--2: #6c7172;
$color-3--3: #454b4c;
$color-3--4: #eceff0;

// ## Color-4
$color-4--1: #fad764;
$color-4--2: #f0b600;
$color-4--3: #de7d00;
$color-4--4: #e14924;
$color-4--5: #605aa1;


// Guipavas.bzh custom background pattern
%bg-pattern-dark {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKUlEQVQImWP8//8/AzJgZGQ0ZsQiyMDw//9/BqiEMVwGSRDOR1UBFQQA3uYYiqmEqeIAAAAASUVORK5CYII=');
}
%bg-pattern-light {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHklEQVQImWP8//8/AxowZsQiyMCErALGwKnSGF0QAMDuCZgts1kgAAAAAElFTkSuQmCC');
}

//%responsive-scrollbar {
//    width: 100%;
//    overflow-y: auto;
//    &::-webkit-scrollbar {
//        -webkit-appearance: none;
//        width: 7px;
//    }
//    &::-webkit-scrollbar-thumb {
//        border-radius: 4px;
//        background-color: rgba($color-black, .5);
//        -webkit-box-shadow: 0 0 1px rgba($color-white, .5);
//    }
//}
