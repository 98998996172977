// @name Title h2
$content-h2__font-size: 2.4;
.rte h2, .rte .h2, .h2 {
    font-size: #{$content-h2__font-size}em;
    margin: em(6, $content-h2__font-size) 0 em(2.5, $content-h2__font-size) 0;
}

.rte {

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        padding-left: 8rem;
        &:before {
            font-size: 2.2rem;
            top: 1.7em;
            left: 0.5em;
        }
        &:after {
            left: 25px;
            width: 150%;
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 2.1;
    $content-blockquote-icon-1__font-size: 3;
    blockquote {
        padding-left: em(7, $content-blockquote__font-size);
        &:before {
            font-size: em($content-blockquote-icon-1__font-size, $content-blockquote__font-size);
            top: em(3, $content-blockquote-icon-1__font-size);
            left: em(1.5, $content-blockquote-icon-1__font-size);
        }
        &:after {
            font-size: 4.5rem;
            top: 0.5em;
        }
    }

}
