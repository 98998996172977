.home-convenient {
    .home-convenient__item {
        width: 100%;
        .home-convenient-section {
            padding-right: 0;
            &.link-bloc__context {
                padding-right: 4rem;
            }
            &__readmore {
                a:before {
                    vertical-align: initial;
                }
            }

        }
    }
    .home-convenient-rapides {
        li {
            width: (100% / 6);
        }
    }

    &-signaler {
        &__wrap {
            padding: 2rem 2.5rem;
            &:after {
                left: 10rem;
            }
        }
        h2 {
            max-width: 250px;
            flex-shrink: 0;
            white-space: normal;
        }
        p {
            margin: 0 5rem 0 3rem;
        }
    }
}
