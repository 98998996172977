.home-banner {
    .item {
        &-wrap {
            width: 60%;
            top: 30%;
        }
    }
    .subtitle {
        font-size: 1.8em;
    }
    .title {
        font-size: 3em;
    }
}
