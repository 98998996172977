.home-gallery {
    .image-gallery {
        &__lg {
            width: 100%;
            order: 0;
        }
        &__sm {
            .image-gallery-item {
                width: 100%;
            }
        }
    }
}
