// @name Search box
// @description Search module

.button-1--no-icon.search-btn-outside {
    align-self: stretch;
    background-color: $color-2--1;
    width: 12rem;
    min-width: 12rem;
    margin-left: auto;
    border: none;
    padding-right: 3rem;
    margin-right: -3rem;
    @extend %bg-pattern-dark;

    transform: skewX(-23deg);

    > svg {
        position: relative;
        transform: skewX(23deg);
        width: 2.2rem;
        height: 2.2rem;
        fill: $color-white;
        z-index: 10;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient($color-2--1, transparent);
        z-index: 1;
    }

    &:hover {
        background-color: $color-1--1;
    }
}

.search-box {
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 35rem);
    height: 100%;
    //display: flex;
    z-index: 10;
    padding-left: 10rem;
    align-items: center;
    background-color: $color-1--2;
    display: none;

    form {
        margin: 0;
        width: 100%;
        display: block;
        position: relative;
        height: 100%;
    }

    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.8em;
    }

    .search-box__fields-wrapper,
    .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {

        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;

            label {
                color: $color-white;
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
                width: 1%;
            }

            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
        }
    }

    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
            display: table;
            height: 100%;

            > * {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    &__wrapper {
        display: block;
        width: 100%;
        position: relative;
        height: 100%;
    }

    &__button-wrapper {
        width: 1.6rem;
    }

    &__fields-wrapper {
        padding: 0 1.8rem 0 0;
    }

    &__button.button-1 {
        background-color: $color-1--3;
        width: 12rem;
        height: 11.7rem;
        border: none;
        padding-right: 3rem;
        margin-right: -3rem;
        @extend %bg-pattern-dark;

        transform: skewX(-23deg);

        > svg {
            transform: skewX(23deg);
            width: 2.2rem;
            height: 2.2rem;
            fill: $color-white;
            position: relative;
            z-index: 10;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: linear-gradient($color-1--3, transparent);;
            z-index: 1;
        }

        &:hover {
            background-color: $color-1--1;
        }
    }
}

// @name Search list
$pertinence__font-size: 1.2;
.pertinence {
    font-size: #{$pertinence__font-size}em;
    span {
        font-size: em(1, 1.2);
    }
}

.pertinence__metter {
    display: inline-block;
    vertical-align: middle;
    width: em(10, $pertinence__font-size);
    height: em(1, $pertinence__font-size);
    padding: em(.2, $pertinence__font-size);
    background: $color-3--2;
    margin: 0 em(1, $pertinence__font-size);
    > span {
        display: block;
        height: em(.8, $pertinence__font-size);;
        background: $color-1--2;
    }
}
