// @name Sitemap
.sitemap {
    margin: 2em 0 3em;
}

.sitemap__listitems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    > * {
        flex: 0 1 31.3333%;
        margin: 0 1% 4em;
    }
}

.sitemap__header {
    border-bottom: 1px solid $color-3--2;
    margin-bottom: 1em;
}

.sitemap__title {
    font-size: 2em;
    font-weight: $bold;
    margin-bottom: em(1.5, 2);
    color: $color-black;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.sitemap__teaser {
    font-size: 1.4em;
    font-weight: $light;
    margin: em(.5, 1.4) 0 em(1, 1.4);
}

.sitemap__listitems2 {
    text-transform: uppercase;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.sitemap__item2 {
    @include default-icons-absolute-before('\e027', 1.4rem, $color-2--2, .2rem, inherit, inherit, 0);
    padding-left: 1.5em;
    margin: .5em 0;
    > a {
        font-size: 1.4em;
    }
}

.sitemap__listitems3 {
    margin-left: 1.5em;
    margin-bottom: 1.3em;
}

.sitemap__item3 {
    @include default-icons-absolute-before('\e027', 1.4rem, $color-1--2, .2rem, inherit, inherit, 0);
    padding-left: 1.5em;
    margin: .5em 0;
    > a {
        font-size: 1.3em;
    }
}

.sitemap__listitems4 {
    margin-left: 1.8em;
    margin-bottom: 1.3em;
}

.sitemap__item4 {
    @include default-icons-absolute-before('\e06c', .01rem, $color-black, .4rem, inherit, inherit, 0);
    padding-left: 1em;
    margin: .5em 0;
    > a {
        font-size: 1.2em;
    }
    &:before {
        font-size: 0.65rem;
    }
}
