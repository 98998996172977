.home-espace {
    &__left {
        &-1,
        &-2 {
            width: 100%;
            float: none;
        }
        &-2 {
            padding: 0;
            margin-bottom: 2rem;
        }
    }

    &__articles {
        .espace-item {
            width: 100%;
            margin-left: 0 !important;
        }
    }

    &__footer {
        a {
            font-size: 1.5em;
            padding: 1em 1.5em;
        }
    }

}
