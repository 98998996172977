// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding: 3em 2em;
}

.section-main__wrapper-2 {
    display: block;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

// @name Header page
.header {
    &__wrapper {
        margin-left: 5.5rem;
        height: 6.6rem;
        .menu-user {
            display: none;
        }
        .menu-main-1__wrapper {
            order: 1;
            flex-grow: 0;
        }
    }
}

.top-of-content {
    display: none;
}

// @name Footer
.footer {
    .footer__wrapper {
        padding: 6em 2em;
    }

    .footer__wrapper-2 {
        > * {
            &:not(:last-child) {
                margin-right: 3em;
            }
        }
    }
}
