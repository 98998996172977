// @name block rencontre

// @name Block rencontre in go-so-far
.block-rencontre {
    padding: 4em 0 6em;
    &__wrapper {
        margin: 0;
        .rencontre-item {
            width: 100%;
            margin: 0 0 3em;
        }
    }
}

.rencontre-item {
    &__content {
        padding: 4rem 2rem 2rem 0;
    }
}
